import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.string.replace-all.js";
export default defineComponent({
  name: 'WithRubleIcon',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var innerHtml = computed(function () {
      return props.text.replaceAll('₽', '<span class="ruble-icon">₽</span>');
    });
    return {
      innerHtml: innerHtml
    };
  }
});