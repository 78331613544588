import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue';
/** Служит для отображения полноэкранного диалога со списком проектов. */
export default defineComponent({
  name: 'OurProjectsDialog',
  components: {
    BaseFullscreenDialog: BaseFullscreenDialog
  },
  props: {
    /** Определяет, является ли диалог с проектами активным. */
    isActive: {
      type: Boolean,
      default: false
    },
    /** Массив с проектами, которые необходимо отобразить. */
    projects: {
      type: Array,
      required: true
    }
  },
  emits: ['click:close'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var title = 'Наши проекты';
    function handleClose() {
      emit('click:close');
    }
    function goToProject(url) {
      location.assign(url);
    }
    return {
      title: title,
      handleClose: handleClose,
      goToProject: goToProject
    };
  }
});