import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue';
import BottomControlsButtons from '~/core/components/BottomControlsButtons/BottomControlsButtons.vue';
import ResultsPlaceholder from '~/core/components/ResultsPlaceholer/ResultsPlaceholder.vue';
import { useToggleable } from '~/core/composables';
export default defineComponent({
  name: 'FirstMedcardAdditionPopup',
  components: {
    ResultsPlaceholder: ResultsPlaceholder,
    BaseFullscreenDialog: BaseFullscreenDialog,
    BottomControlsButtons: BottomControlsButtons
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'more', 'share'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      expose = _ref.expose;
    var refProps = toRefs(props);
    var _useToggleable = useToggleable(refProps.value, emit, 'input'),
      isActive = _useToggleable.isActive,
      show = _useToggleable.show,
      hide = _useToggleable.hide;
    function emitMore() {
      hide();
      emit('more');
    }
    function emitShare() {
      hide();
      emit('share');
    }
    expose({
      show: show,
      hide: hide
    });
    return {
      isActive: isActive,
      emitMore: emitMore,
      emitShare: emitShare
    };
  }
});