import { GTAG_ID } from '~/core/constants';

// https://developers.google.com/tag-platform/gtagjs/install?hl=ru#google-analytics
export default (function (_ref, inject) {
  var router = _ref.app.router;
  var script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=".concat(GTAG_ID));
  document.head.appendChild(script);

  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  // @ts-ignore
  gtag('js', new Date());
  // @ts-ignore
  gtag('config', GTAG_ID);
  inject('gtag', gtag);
  router === null || router === void 0 ? void 0 : router.afterEach(function (to) {
    // @ts-ignore
    gtag('config', GTAG_ID, {
      page_path: to.fullPath
    });
  });
});