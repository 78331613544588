import useMainSnackbarStore from '~/stores/useMainSnackbarStore';
export default defineComponent({
  name: 'MainSnackbar',
  setup: function setup() {
    var snackbar = useMainSnackbarStore();
    var timeout = computed(function () {
      return snackbar.cancelText ? 7000 : 4000;
    });
    function cancelHandler() {
      var _snackbar$cancelHandl;
      if (snackbar.isActive && !snackbar.cancelText) {
        throw new Error('Не передан cancelable.');
      }
      (_snackbar$cancelHandl = snackbar.cancelHandler) === null || _snackbar$cancelHandl === void 0 ? void 0 : _snackbar$cancelHandl.call(snackbar);
      snackbar.close();
    }
    return {
      snackbar: snackbar,
      timeout: timeout,
      cancelHandler: cancelHandler
    };
  }
});