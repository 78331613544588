import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VTextField } from 'vuetify/lib/components';
export default {
  name: 'BaseTextField',
  functional: true,
  props: {
    // TODO: УДАЛИТЬ ПОСЛЕ ОБНОВЛЕНИЯ vuetify
    // Данная фича добавлена во vuetify 2.6.0+ https://github.com/vuetifyjs/vuetify/releases/tag/v2.6.0
    hideSpinButtons: {
      // Убирает стрелочки у input[type="number"]
      type: Boolean,
      default: false
    }
  },
  render: function render(h, _ref) {
    var data = _ref.data,
      children = _ref.children,
      props = _ref.props;
    return h(VTextField, _objectSpread(_objectSpread({}, data), {}, {
      props: {
        outlined: true
      },
      class: ['base-text-field', {
        'base-text-field_hidden-spin-buttons': props.hideSpinButtons
      }, data.class]
    }), children);
  }
};