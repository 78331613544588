import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue';
import { useToggleable } from '~/core/composables';
import { MedcardShare } from '~/core/components/MedcardShare';
export default defineComponent({
  name: 'MedcardSharePopup',
  components: {
    MedcardShare: MedcardShare,
    BaseFullscreenDialog: BaseFullscreenDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      expose = _ref.expose;
    var medcardShare = ref(null);
    var refProps = toRefs(props);
    var _useToggleable = useToggleable(refProps.value, emit, 'input'),
      isActive = _useToggleable.isActive,
      show = _useToggleable.show,
      hide = _useToggleable.hide;
    watch(isActive, function (val) {
      if (val) {
        var _medcardShare$value;
        (_medcardShare$value = medcardShare.value) === null || _medcardShare$value === void 0 ? void 0 : _medcardShare$value.resetState();
      }
    });
    function handleScanned() {
      hide();
    }
    expose({
      show: show
    });
    return {
      isActive: isActive,
      medcardShare: medcardShare,
      handleScanned: handleScanned
    };
  }
});