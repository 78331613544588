import { MTLinkShareApi } from '~/core/api'
import { uuidv4 } from '~/core/utils/uuidv4'
import type { MedcardSessionData } from '~/core/api/mtlink.api'

export const getShareLink = async (payload: MedcardSessionData = {}) => {
  const uuid = uuidv4()

  const {
    data: {
      anonymousToken: masterAnonymousToken,
      accessCode,
    },
  } = await MTLinkShareApi.createMasterMtlinkSession(uuid)
  const {
    data: {
      anonymousToken: infoAnonymousToken,
    },
  } = await MTLinkShareApi.createInfoMtlinkSession(uuid, payload)
  const { data: { shortLink } } = await MTLinkShareApi.getSharingShortLink({
    infoAnonymousToken,
    masterAnonymousToken,
  })

  return { link: shortLink, code: accessCode }
}
