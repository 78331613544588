import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.trim.js";
export default defineComponent({
  name: 'SearchBar',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'Найти'
    },
    type: {
      type: String,
      default: 'text'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'focus', 'blur', 'click:clear'],
  setup: function setup() {
    var rules = [function (v) {
      var _trim;
      return ((_trim = (v || '').trim()) === null || _trim === void 0 ? void 0 : _trim.length) <= 100 || 'Не больше 100 символов';
    }];
    return {
      rules: rules
    };
  }
});