var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.drawer ? _vm.VNavigationDrawer : _vm.VDialog,_vm._g(_vm._b({tag:"component",attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_vm._t("activator",null,null,scope)]}}],null,true),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},'component',{
    ...(
      _vm.drawer ? {
        fixed: true,
        temporary: true,
        right: true,
        width: 400,
      } : {
        transition: 'dialog-bottom-transition',
        scrollable: true,
        persistent: true,
        retainFocus: false,
      }
    ),
    ..._vm.$attrs,
  },false),{
    ..._vm.$listeners,
  }),[_vm._v(" "),_c('div',{staticClass:"d-flex flex-column overflow-auto",class:{
      'ui-kit-bg-gray-0': !_vm.dark,
      'ui-kit-text': _vm.dark,
    },attrs:{"data-test":"fullscreen-dialog-content-container"}},[(_vm.showSmartBanner && _vm.$device.isMobile)?_c('AppSmartBanner',{staticClass:"flex-grow-0",attrs:{"app":false}}):_vm._e(),_vm._v(" "),(!_vm.hideCloseBtn)?_c('div',{staticClass:"flex-grow-0"},[_c('AppToolbar',{attrs:{"title":_vm.title,"borderless":_vm.borderlessTitle || Boolean(_vm.$scopedSlots['title-append']),"close-to":!_vm.backTo && !_vm.hideCloseBtn,"back-to":_vm.backTo && !_vm.hideCloseBtn,"append-icon":_vm.toolbarAppendIcon,"dark":_vm.dark},on:{"click:close":_vm.handleClickClose,"click:back":_vm.handleClickClose,"click:icon":_vm.emitClickAppendIcon},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_vm._t("toolbar-append-icon")]},proxy:true}],null,true)},[_vm._t("title")],2),_vm._v(" "),_vm._t("title-append"),_vm._v(" "),(!_vm.borderlessTitle && _vm.$scopedSlots['title-append'])?_c('v-divider',{staticClass:"mt-2"}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('v-sheet',{staticClass:"flex-grow-1",class:{
        'overflow-y-auto': _vm.fixedToolbar && !_vm.persistentScroll,
        'overflow-y-scroll': _vm.persistentScroll,
        'ui-kit-text': _vm.dark,
      },attrs:{"data-test":"dialog-container"}},[_c('v-sheet',{staticClass:"d-flex flex-column",class:{
          'limit-container': !_vm.fullWidth,
          'ui-kit-text': _vm.dark,
          'page-border': _vm.pageBorder,
        },attrs:{"data-test":"dialog-limit-container","height":"100%"}},[_vm._t("default")],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }