import { defineStore } from 'pinia';
import { PD_URL, PROFILE_GETTERS } from '~/core/constants';
import useCountriesStore from '~/stores/useCountriesStore';
export default defineStore('cis', function () {
  var _useNuxtApp = useNuxtApp(),
    $featureFlag = _useNuxtApp.$featureFlag,
    $store = _useNuxtApp.$store;
  var isYDoc = computed(function () {
    return $featureFlag('sff_cis') && process.env.domain === 'CIS';
  });
  var pdSiteName = computed(function () {
    return isYDoc.value ? 'YDoc' : 'ПроДокторов';
  });
  var isUserLogged = computed(function () {
    return Boolean($store.state.profile.phone);
  });
  var pdUrl = computed(function () {
    if (!$featureFlag('sff_cis')) {
      return PD_URL;
    }
    return isUserLogged.value ? $store.getters[PROFILE_GETTERS.PD_URL] : useCountriesStore().pdUrl;
  });
  return {
    isYDoc: isYDoc,
    pdSiteName: pdSiteName,
    pdUrl: pdUrl
  };
});