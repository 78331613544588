import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var BaseBadge = function BaseBadge() {
  return import('../../core/components/Base/BaseBadge.vue' /* webpackChunkName: "components/base-badge" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseBottomSheet = function BaseBottomSheet() {
  return import('../../core/components/Base/BaseBottomSheet.vue' /* webpackChunkName: "components/base-bottom-sheet" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseBtn = function BaseBtn() {
  return import('../../core/components/Base/BaseBtn.vue' /* webpackChunkName: "components/base-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseCheckbox = function BaseCheckbox() {
  return import('../../core/components/Base/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseCircularPreloader = function BaseCircularPreloader() {
  return import('../../core/components/Base/BaseCircularPreloader.vue' /* webpackChunkName: "components/base-circular-preloader" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseContainer = function BaseContainer() {
  return import('../../core/components/Base/BaseContainer.vue' /* webpackChunkName: "components/base-container" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseFullscreenDialog = function BaseFullscreenDialog() {
  return import('../../core/components/Base/BaseFullscreenDialog.vue' /* webpackChunkName: "components/base-fullscreen-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseIcon = function BaseIcon() {
  return import('../../core/components/Base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseImg = function BaseImg() {
  return import('../../core/components/Base/BaseImg.vue' /* webpackChunkName: "components/base-img" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseNumberMarker = function BaseNumberMarker() {
  return import('../../core/components/Base/BaseNumberMarker.vue' /* webpackChunkName: "components/base-number-marker" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BasePage = function BasePage() {
  return import('../../core/components/Base/BasePage.vue' /* webpackChunkName: "components/base-page" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BasePagePreloader = function BasePagePreloader() {
  return import('../../core/components/Base/BasePagePreloader.vue' /* webpackChunkName: "components/base-page-preloader" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseRadio = function BaseRadio() {
  return import('../../core/components/Base/BaseRadio.vue' /* webpackChunkName: "components/base-radio" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSheetItem = function BaseSheetItem() {
  return import('../../core/components/Base/BaseSheetItem.vue' /* webpackChunkName: "components/base-sheet-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSkeletonLoader = function BaseSkeletonLoader() {
  return import('../../core/components/Base/BaseSkeletonLoader.vue' /* webpackChunkName: "components/base-skeleton-loader" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSkeletonTextPreloader = function BaseSkeletonTextPreloader() {
  return import('../../core/components/Base/BaseSkeletonTextPreloader.vue' /* webpackChunkName: "components/base-skeleton-text-preloader" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSlider = function BaseSlider() {
  return import('../../core/components/Base/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSliderItem = function BaseSliderItem() {
  return import('../../core/components/Base/BaseSliderItem.vue' /* webpackChunkName: "components/base-slider-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseSwitch = function BaseSwitch() {
  return import('../../core/components/Base/BaseSwitch.vue' /* webpackChunkName: "components/base-switch" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseTag = function BaseTag() {
  return import('../../core/components/Base/BaseTag.vue' /* webpackChunkName: "components/base-tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseTextField = function BaseTextField() {
  return import('../../core/components/Base/BaseTextField.vue' /* webpackChunkName: "components/base-text-field" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BaseTextarea = function BaseTextarea() {
  return import('../../core/components/Base/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export { NoScript } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Link } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Base } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Title } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Meta } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Style } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Head } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Html } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';
export { Body } from '../../node_modules/@nuxt/bridge/dist/runtime/head/components';

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}