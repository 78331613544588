import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineAsyncComponent, defineComponent } from 'vue';
export var defineAsyncComponentWithLoader = function defineAsyncComponentWithLoader(component) {
  var OverlayLoading = function OverlayLoading() {
    return import('~/core/components/OverlayLoading/OverlayLoading.vue');
  };
  return defineAsyncComponent({
    loader: component,
    delay: 100,
    loadingComponent: defineComponent({
      functional: true,
      render: function render(h) {
        return h(OverlayLoading, {
          props: {
            absolute: true,
            value: true
          }
        });
      }
    })
  });
};