import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/esnext.string.replace-all.js";
import { truncate } from 'lodash-es';
import Divider from '~/core/components/Divider/Divider.vue';
import ExpansionPanel from '~/core/components/ExpansionPanel/ExpansionPanel.vue';
import RateAbout from '~/features/Rates/components/RateAbout.vue';
import MediaRules from '~/features/Rates/components/RateEdit/MediaRules.vue';
var LINK_MAX_LEN = 28;
export default defineComponent({
  name: 'WarningMessage',
  components: {
    ExpansionPanel: ExpansionPanel,
    RateAbout: RateAbout,
    MediaRules: MediaRules,
    Divider: Divider
  },
  props: {
    message: {
      type: Object,
      default: null
    },
    detailInfo: {
      type: Object,
      default: null
    },
    aboutFrom: {
      type: Object,
      default: null
    },
    about: {
      type: Object,
      default: null
    },
    color: {
      type: String,
      default: 'ui-kit-bg-warning'
    },
    isActive: {
      type: Boolean
    }
  },
  setup: function setup(props) {
    var isShowAboutFrom = ref(false);
    var isShowDetail = ref(false);
    var hasDetailInfo = computed(function () {
      var _props$detailInfo;
      return Boolean((_props$detailInfo = props.detailInfo) === null || _props$detailInfo === void 0 ? void 0 : _props$detailInfo.link);
    });
    var messageTextWithLinks = computed(function () {
      var _props$message$text$r, _props$message;
      var re = /([a-z]+:\/\/)([A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9-]*(\.[A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9-]*)+)((\/|&)\S*)?/g;
      return (_props$message$text$r = (_props$message = props.message) === null || _props$message === void 0 ? void 0 : _props$message.text.replaceAll(re, function (link) {
        var linkText = truncate(link, {
          length: LINK_MAX_LEN
        });
        return "<a href=\"".concat(link, "\">").concat(linkText, "</a>");
      })) !== null && _props$message$text$r !== void 0 ? _props$message$text$r : '';
    });
    function handleDetailLinkClick() {
      var _props$detailInfo2;
      if (((_props$detailInfo2 = props.detailInfo) === null || _props$detailInfo2 === void 0 ? void 0 : _props$detailInfo2.link) === 'Опубликованный отзыв') {
        var _props$detailInfo3;
        location.assign((_props$detailInfo3 = props.detailInfo) === null || _props$detailInfo3 === void 0 ? void 0 : _props$detailInfo3.detail);
        return;
      }
      isShowDetail.value = true;
    }
    return {
      isShowAboutFrom: isShowAboutFrom,
      isShowDetail: isShowDetail,
      hasDetailInfo: hasDetailInfo,
      messageTextWithLinks: messageTextWithLinks,
      handleDetailLinkClick: handleDetailLinkClick
    };
  }
});