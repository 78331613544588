import useMainSnackbarStore from '~/stores/useMainSnackbarStore'

export default function() {
  const $snackbar = useMainSnackbarStore()

  if (!$snackbar.isActive) {
    return
  }

  if ($snackbar.dontCloseOnRouteChange) {
    $snackbar.dontCloseOnRouteChange = false
  } else {
    $snackbar.close()
  }
}
