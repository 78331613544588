import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/profile/confirmation/web'

type SendCodePayload = {
  phone: string,
  code: string,
  serviceId: string | null,
}

export type Country = {
  name: string
  iso: string
  phoneCode: string
  maskForInput: string
  flagPictureUrl: string
  pdDomain: string
}

export default {
  requestCode(payload: { phone: string, forceSms?: boolean, gRecaptchaResponse?: string, confirmationFlow?: string }) {
    return masterClient.post<{ code: 'call' | 'sms' | 'vk', message: string, serviceId: string, attemptsExceeded: boolean }>(
      `${RESOURCE}/request/`,
      payload,
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true,
      },
    )
  },
  confirmCode(payload: SendCodePayload) {
    return masterClient.post(
      `${RESOURCE}/confirm/`,
      { ...payload, mobileApp: false },
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true,
      },
    )
  },
  getCountries() {
    return masterClient.get<Country[]>('/countries/', { camelize: true })
  },
}
