import { GALLERY_IS_COMPARE_ACTIVE_INJECTION } from './constants';
var BTNS_SIZE_BREAKPOINT_FOR_COMPARE_MODE = 1475;
export default defineComponent({
  name: 'FullscreenGalleryToolbarBtn',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var isCompareActive = inject(GALLERY_IS_COMPARE_ACTIVE_INJECTION);
    var isBig = computed(function () {
      return isCompareActive !== null && isCompareActive !== void 0 && isCompareActive.value ? $vuetify.breakpoint.width > BTNS_SIZE_BREAKPOINT_FOR_COMPARE_MODE : $vuetify.breakpoint.lgAndUp;
    });
    return {
      isBig: isBig
    };
  }
});