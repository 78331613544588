import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/profile'

export default {
  rulesAgree() {
    return masterClient.patch(`${RESOURCE}/rules/`, {}, { camelize: true })
  },
  recaptchaValidation() {
    return masterClient.get<{ recaptchaWillBeValidated: boolean }>(`${RESOURCE}/recaptcha_validation/`, { camelize: true })
  },
}
