import axiosRetry from 'axios-retry'
import { createDefaultClient } from '~/core/functions/client/createDefaultClient'
import { retryCondition, retryDelay } from '~/core/functions/axiosRetry'
import { PD_URL } from '~/core/constants'

export default function getPdClient(pdUrl: string = PD_URL) {
  const pdClient = createDefaultClient(pdUrl)

  pdClient.interceptors.request.use((config) => {
    config.withCredentials = true

    return config
  })

  axiosRetry(pdClient, {
    retries: 3,
    retryCondition,
    retryDelay,
  })

  return pdClient
}
