import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'BaseNumberMarker',
  props: {
    textColor: {
      type: String,
      default: 'ui-kit-text-secondary--text'
    },
    backgroundColor: {
      type: String,
      default: 'ui-kit-bg-gray-60'
    },
    text: {
      type: [String, Number],
      default: ''
    }
  }
});