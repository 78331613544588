import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled';
export default defineNuxtPlugin(function () {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  return {
    provide: {
      featureFlag: function featureFlag(feature) {
        return isFeatureEnabled(feature, $store.state.featureFlags);
      }
    }
  };
});