export * from './files'
export * from './support'
export * from './inputRules'
export * from './store'
export * from './metrics'
export * from './dialog'
export * from './urls'
export * from './timer'
export * from './ourProjects'

export const NO_AVATAR = '/graphics/no-avatar.svg'
export const NO_AVATAR_DOCTOR = '/graphics/no-avatar-doctor.svg'
export const NO_AVATAR_CLINIC = '/graphics/no-avatar-clinic.svg'
export const NO_AVATAR_SERVICE = '/graphics/no-avatar-service.svg'
export const NO_AVATAR_ANALYSIS = '/graphics/no-avatar-analysis.svg'
export const CONTAINER_WIDTH = 744
export const IS_STAGE = process.env.buildTarget === 'stage' || false
export const MINIMUM_ANDROID_VERSION = 8
export const MOSCOW_TIME_DELTA = 3
