import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.includes.js";
import VClamp from 'vue-clamp';
import { isArray } from 'lodash-es';
import { pluralize } from '~/core/utils/pluralize';
import { documentDeclension } from '~/core/constants/wordForms';
import { CORE_GETTERS } from '~/core/constants';
import { DOCUMENTS_ACTIONS, DOCUMENTS_GETTERS } from '~/core/constants/store/medcard/documents';
import NewFolderPopup from '~/features/Medcard/components/folders/NewFolderPopup.vue';
export default defineComponent({
  name: 'MedcardShareSelectItems',
  components: {
    NewFolderPopup: NewFolderPopup,
    VClamp: VClamp
  },
  props: {
    withAddFolders: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(_, _ref) {
    var expose = _ref.expose;
    var isNewFolderPopupActive = ref(false);
    var allFoldersCheckboxValue = ref(false);
    var femaleCalendarCheckboxValue = ref(false);
    var selectedFoldersIds = ref([]);
    var selectedFoldersIdsCopy = ref([]);
    var oldState = reactive({
      allFoldersCheckboxValue: allFoldersCheckboxValue.value,
      femaleCalendarCheckboxValue: femaleCalendarCheckboxValue.value,
      selectedFoldersIds: _toConsumableArray(selectedFoldersIds.value)
    });
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var documentsCount = computed(function () {
      return pluralize($store.getters[DOCUMENTS_GETTERS.GET_MEDCARD_DOCUMETS_COUNT], documentDeclension);
    });
    var folders = computed(function () {
      return $store.state.medcard.documents.folders.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          documentsText: pluralize(item.documentGroups.length || 0, documentDeclension)
        });
      });
    });
    var femaleCalendarId = computed(function () {
      var activeProfile = $store.getters[CORE_GETTERS.GET_FAMILY_PROFILE]($store.state.medcard.familyProfileUuid);
      return activeProfile === null || activeProfile === void 0 ? void 0 : activeProfile.femaleCalendarId;
    });
    var changed = computed(function () {
      return oldState.femaleCalendarCheckboxValue !== femaleCalendarCheckboxValue.value || oldState.allFoldersCheckboxValue !== allFoldersCheckboxValue.value || oldState.selectedFoldersIds.length !== selectedFoldersIds.value.length || !oldState.selectedFoldersIds.every(function (val) {
        return selectedFoldersIds.value.includes(val);
      });
    });
    var selected = computed(function () {
      return allFoldersCheckboxValue.value || selectedFoldersIds.value.length > 0 || femaleCalendarCheckboxValue.value;
    });
    var isFemaleCalendarDisabled = computed(function () {
      return !$store.state.profile.isFemaleCalendarEnabled;
    });
    var selectedItems = computed(function () {
      return {
        folderIds: allFoldersCheckboxValue.value ? undefined : selectedFoldersIds.value,
        femaleCalendarId: femaleCalendarCheckboxValue.value && !isFemaleCalendarDisabled.value ? femaleCalendarId.value : null
      };
    });
    watch(allFoldersCheckboxValue, function (val) {
      if (val) {
        selectedFoldersIdsCopy.value = _toConsumableArray(selectedFoldersIds.value);
        selectedFoldersIds.value = [];
      } else {
        selectedFoldersIds.value = _toConsumableArray(selectedFoldersIdsCopy.value);
      }
    });
    watch(folders, function (val) {
      allFoldersCheckboxValue.value = !val.length;
    }, {
      immediate: true
    });
    function resetState() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        folderIds = _ref2.folderIds,
        femaleCalendarId = _ref2.femaleCalendarId;
      allFoldersCheckboxValue.value = !isArray(folderIds);
      femaleCalendarCheckboxValue.value = Boolean(femaleCalendarId);
      selectedFoldersIds.value = folderIds ? _toConsumableArray(folderIds) : [];
      selectedFoldersIdsCopy.value = _toConsumableArray(selectedFoldersIds.value);
      oldState.femaleCalendarCheckboxValue = femaleCalendarCheckboxValue.value;
      oldState.allFoldersCheckboxValue = allFoldersCheckboxValue.value;
      oldState.selectedFoldersIds = _toConsumableArray(selectedFoldersIdsCopy.value);
    }
    function handleFolderCreated() {
      $store.dispatch(DOCUMENTS_ACTIONS.FETCH_MEDCARD_FOLDERS);
    }
    expose({
      changed: changed,
      selected: selected,
      selectedItems: selectedItems,
      resetState: resetState
    });
    return {
      isNewFolderPopupActive: isNewFolderPopupActive,
      allFoldersCheckboxValue: allFoldersCheckboxValue,
      femaleCalendarCheckboxValue: femaleCalendarCheckboxValue,
      selectedFoldersIds: selectedFoldersIds,
      documentsCount: documentsCount,
      folders: folders,
      femaleCalendarId: femaleCalendarId,
      isFemaleCalendarDisabled: isFemaleCalendarDisabled,
      handleFolderCreated: handleFolderCreated,
      // Для тестов
      selected: selected,
      selectedItems: selectedItems
    };
  }
});