import { upperFirst } from 'lodash-es'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import type { MedcardDocumentGroupItem, MedcardDocumentGroupListItemList } from '~/features/Medcard/types'
import type { MedcardDocumentGroupListItemDto } from '~/features/Medcard/api/medcard.api'
import getDocumentGroupDate from '~/features/Medcard/functions/getDocumentGroupDate'

type ReduceByDate = (rawDocumentGroups: MedcardDocumentGroupListItemDto[]) =>
  MedcardDocumentGroupListItemList[]

const reduceByDate: ReduceByDate = rawDocumentGroups =>
  Array.from(rawDocumentGroups.reduce(
    (result, rawItem) => {
      const targetDate = getDocumentGroupDate(rawItem)

      const item = {
        id: rawItem.id,
        documentInfo: rawItem.documentInfo,
        documentType: rawItem.documentType,
        dateVisit: format(parseISO(targetDate), 'd MMMM yyyy', { locale: ru }),
        dateGenerated: parseISO(targetDate),
        isNew: rawItem.isNew,
      }

      const key = upperFirst(format(item.dateGenerated, 'LLLL yyyy', { locale: ru }))

      if (result.has(key)) {
        result.get(key)?.push(item)
      } else {
        result.set(key, [item])
      }

      return result
    },
    new Map<string, MedcardDocumentGroupItem[]>(),
  ))

export default reduceByDate
