import maskit from '~/plugins/mask/functions/maskit'
import dynamicMask from '~/plugins/mask/functions/dynamic-mask'
import type { MaskTokens } from '~/plugins/mask/types'

// Facade to maskit/dynamicMask when mask is String or Array
const masker = (
  value: string,
  mask: Array<string> | string,
  masked = true,
  tokens: MaskTokens,
  caretPosition: number,
) => Array.isArray(mask)
  ? dynamicMask(maskit, mask, tokens)(value, mask, masked, caretPosition)
  : maskit(value, mask, masked, tokens, caretPosition)

export default masker
