import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { StoreAppLinks } from '~/core/enums/appLinks';
export var useAppLink = function useAppLink($device, $route) {
  var route = $route !== null && $route !== void 0 ? $route : useRoute();
  var DEEP_LINK_HOST = 'medtochka://app';
  var appLink = computed(function () {
    return $device.isIos || $device.isMacOS ? StoreAppLinks.AppStore : StoreAppLinks.PlayMarket;
  });
  var deepLinkPath = computed(function () {
    switch (route.name) {
      case 'authorization':
        return '/authorization/';
      case 'medcard':
        return '/medcard/';
      case 'appointments-id':
        return "/appointments/".concat(route.params.id, "/");
      case 'appointments-id-transfer':
        return "/appointments/".concat(route.params.id, "/transfer/");
      case 'analyses-id':
        return "/analyses/".concat(route.params.id, "/");
      case 'rates-type-id':
        return "/rates/".concat(route.params.type, "/").concat(route.params.id, "/");
      case 'rates-type-id-chat':
        return "/rates/".concat(route.params.type, "/").concat(route.params.id, "/chat/");
      case 'profile':
        return '/profile/';
      default:
        return '/';
    }
  });
  var deepLink = computed(function () {
    return DEEP_LINK_HOST + deepLinkPath.value;
  });
  return {
    appLink: appLink,
    deepLink: deepLink
  };
};