import type { DirectiveBinding } from 'vue'
import { MASK_TOKENS } from '~/plugins/mask/constants/tokens'
import masker from '~/plugins/mask/functions/masker'
import type { MaskOptions } from '~/plugins/mask/types'

export default function(el: HTMLInputElement, binding: DirectiveBinding<MaskOptions>) {
  const config = Array.isArray(binding.value) || typeof binding.value === 'string'
    ? {
        mask: binding.value,
        tokens: MASK_TOKENS,
      }
    : binding.value

  if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
    const els = el.getElementsByTagName('input')

    if (els.length !== 1) {
      throw new Error('v-mask directive requires 1 input, found ' + els.length)
    } else {
      el = els[0]
    }
  }

  el.oninput = function(evt: Event) {
    if (!evt.isTrusted) {
      return
    } // avoid infinite loop

    const { value, caretPosition } = masker(el.value, config.mask, true, config.tokens, el.selectionEnd!)
    el.value = value

    if (el === document.activeElement) {
      el.setSelectionRange(caretPosition, caretPosition)
      setTimeout(function() {
        el.setSelectionRange(caretPosition, caretPosition)
      }, 0)
    }

    el.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
  }

  const { value: newDisplay, caretPosition } = masker(el.value, config.mask, true, config.tokens, el.selectionEnd!)

  if (newDisplay !== el.value) {
    el.value = newDisplay
    el.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))

    if (el === document.activeElement && newDisplay.length !== caretPosition) {
      el.setSelectionRange(caretPosition, caretPosition)
      setTimeout(function() {
        el.setSelectionRange(caretPosition, caretPosition)
      }, 0)
    }
  }
}
