import { masterClient } from '~/core/utils/clients/masterClient'
import { getFamilyProfileUuidHeader } from '~/core/functions/client/getFamilyProfileUuidHeader'
import { medcardClient } from '~/core/utils/clients/medcardClient'
import type { MedcardSessionData } from '~/core/api/mtlink.api'

const RESOURCE = '/mt_link'

export default {
  createMasterMtlinkSession(uuid: string) {
    return masterClient.post<{ anonymousToken: string, accessCode: string }>(
      `${RESOURCE}/session_tokens/by_link/`,
      { clientUuid: uuid },
      {
        headers: getFamilyProfileUuidHeader(),
        camelize: true,
      },
    )
  },
  createInfoMtlinkSession(uuid: string, payload: MedcardSessionData = {}) {
    return medcardClient.post<{ anonymousToken: string, }>(
      `${RESOURCE}/session_tokens/by_link/`,
      {
        clientUuid: uuid,
        folderIds: payload.folderIds,
        femaleCalendarId: payload.femaleCalendarId,
      },
      { camelize: true },
    )
  },
  getSharingShortLink(payload: { masterAnonymousToken: string, infoAnonymousToken: string }) {
    return masterClient.patch<{ shortLink: string }>(`${RESOURCE}/session_tokens/by_link/`, {
      masterAnonymousToken: payload.masterAnonymousToken,
      infoAnonymousToken: payload.infoAnonymousToken,
    }, { camelize: true })
  },
}
