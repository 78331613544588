import { useWindowSize } from '@vueuse/core';
import MobileLayout from '~/core/layouts/Mobile.vue';
import { useAppLink, useSmartAppBannerSetup } from '~/core/composables';
export default defineComponent({
  name: 'Chat',
  components: {
    MobileLayout: MobileLayout
  },
  setup: function setup() {
    useSmartAppBannerSetup();
    var _useNuxtApp = useNuxtApp(),
      $device = _useNuxtApp.$device,
      $vuetify = _useNuxtApp.$vuetify,
      $store = _useNuxtApp.$store;
    var $route = useRoute();
    var _useAppLink = useAppLink($device, $route),
      appLink = _useAppLink.appLink;
    var _useWindowSize = useWindowSize(),
      windowHeight = _useWindowSize.height;
    watch(windowHeight, setRealVH);
    watch(function () {
      return $store.state.overlaysCount;
    }, function (val) {
      document.documentElement.style.overflow = val > 0 ? 'hidden' : '';
      if ($device.isDesktop) {
        document.documentElement.style.paddingRight = val > 0 ? "".concat($vuetify.breakpoint.scrollBarWidth, "px") : '0';
      }
    });
    onBeforeMount(function () {
      if ($device.isMobile) {
        document.documentElement.classList.add('is-mobile');
        document.documentElement.style.overflow = 'hidden';
      }
      setRealVH();
    });
    onBeforeUnmount(function () {
      window.removeEventListener('resize', setRealVH);
    });
    function setRealVH() {
      // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
      document.documentElement.style.setProperty('--vh', "".concat(windowHeight.value / 100, "px"));
    }
    return {
      appLink: appLink
    };
  }
});