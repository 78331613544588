import { ymGoal } from '~/core/utils/yandexReachGoal';
export default defineComponent({
  name: 'SupportContacts',
  props: {
    support: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var text = computed(function () {
      return props.support.phone ? 'Ответим на ваши вопросы быстрее, если позвоните с номера, который указан в профиле.' : 'Ответим на все вопросы и поможем решить проблему.';
    });
    function handleClickMail() {
      ymGoal('clickMail');
    }
    function handleClickPhone() {
      ymGoal('clickCall');
    }
    return {
      text: text,
      handleClickMail: handleClickMail,
      handleClickPhone: handleClickPhone
    };
  }
});