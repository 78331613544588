import { masterClient } from '~/core/utils/clients/masterClient'
import { PD_URL } from '~/core/constants'
import getPdClient from '~/core/utils/clients/getPdClient'
import type { RateDetailsDto, RatesChatInfoDto, RatesChatMessageDto } from '~/features/Rates/api/chat.api.types'

const RESOURCE = 'profile/api'

export default function ChatApi(pdUrl: string = PD_URL) {
  const pdClient = getPdClient(pdUrl)

  return {
    get(token: string | undefined, { type, id }: { type: string, id: string }) {
      return pdClient.get<RatesChatMessageDto[]>(`${pdUrl}/${RESOURCE}/${type}/rates/${id}/chat/messages/`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
        camelize: true,
      })
    },
    getToken() {
      return masterClient.post<{ token: string, userId: number }>(
        '/tokens/pd_access_token/',
        {},
        { camelize: true },
      )
    },
    getTokenWs(token: string | undefined) {
      return pdClient.get<{ wsToken: string }>(`${pdUrl}/${RESOURCE}/get_ws_token/`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
        camelize: true,
      })
    },
    getRate(type: string, id: string) {
      return masterClient.get<RateDetailsDto>(`/rates/chat/${type}/${id}/`, { camelize: true })
    },
    getMedia(mediaURL: string) {
      return masterClient.get<ArrayBuffer>(`/rates${mediaURL}`, {
        responseType: 'arraybuffer',
        camelize: true,
      })
    },
    getInfo(token: string | undefined, { type, id }: { type: string, id: string }) {
      return pdClient.get<RatesChatInfoDto>(`${pdUrl}/${RESOURCE}/${type}/rates/${id}/chat/`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
        camelize: true,
      })
    },
    postMessage(token: string | undefined, { type, id }: { type: string, id: string }, payload: RatesChatMessageDto) {
      return pdClient.post<RatesChatMessageDto>(
      `${pdUrl}/${RESOURCE}/${type}/rates/${id}/chat/messages/`,
      payload,
      {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
        'axios-retry': { retries: 0 },
        camelize: true,
      },
      )
    },
    checkMessage(token: string | undefined, { type, id }: { type: string, id: string }, messageId: number) {
      return pdClient.patch<RatesChatMessageDto>(
      `${pdUrl}/${RESOURCE}/${type}/rates/${id}/chat/messages/${messageId}/`,
      {},
      {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
        camelize: true,
      },
      )
    },
    problemResolve(token: string | undefined, { type, id }: { type: string, id: string }, status: string) {
      return pdClient.patch(
      `${pdUrl}/${RESOURCE}/${type}/rates/${id}/chat/`,
      { status },
      {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
        camelize: true,
      },
      )
    },
  }
}
