import { render, staticRenderFns } from "./NewFolderPopup.vue?vue&type=template&id=1665591f"
import script from "./NewFolderPopup.vue?vue&type=script&lang=ts"
export * from "./NewFolderPopup.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTextField: require('/builds/private/medtochka-frontend/core/components/Base/BaseTextField.vue').default,BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default,BaseFullscreenDialog: require('/builds/private/medtochka-frontend/core/components/Base/BaseFullscreenDialog.vue').default})
