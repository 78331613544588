export var useToggleable = function useToggleable(valueProp, emit, modelEvent) {
  var isActive = ref(false);
  watch(valueProp, function (val) {
    isActive.value = val;
  }, {
    immediate: true
  });
  watch(isActive, function (val) {
    val !== valueProp.value && emitInput(val);
  });
  function show() {
    isActive.value = true;
  }
  function hide() {
    isActive.value = false;
    emitInput(false);
  }
  function emitInput(val) {
    emit(modelEvent, val);
  }
  return {
    isActive: isActive,
    show: show,
    hide: hide,
    emitInput: emitInput
  };
};