import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineComponent } from 'vue';
export var generateYesNoForm = function generateYesNoForm(id) {
  var QuestionnaireFormYesNo = function QuestionnaireFormYesNo() {
    return import('~/core/components/Questionnaire/components/common/QuestionnaireFormYesNo.vue');
  };
  return defineComponent({
    functional: true,
    render: function render(h) {
      return h(QuestionnaireFormYesNo, {
        props: {
          id: id
        }
      });
    }
  });
};