import BaseTextField from '~/core/components/Base/BaseTextField.vue';
import PatronymicTextField from '~/core/components/PatronymicTextField/PatronymicTextField.vue';
import { nameRules, surnameRules } from '~/features/Profile/functions';
export default defineComponent({
  name: 'FIOForm',
  components: {
    PatronymicTextField: PatronymicTextField,
    BaseTextField: BaseTextField
  },
  props: {
    patronymic: {
      type: String,
      default: ''
    },
    forename: {
      type: String,
      default: ''
    },
    surname: {
      type: String,
      default: ''
    },
    isEmptyPatronymic: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:patronymic', 'update:forename', 'update:surname'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var syncedPatronymic = computed({
      get: function get() {
        return props.patronymic;
      },
      set: function set(val) {
        emit('update:patronymic', val);
      }
    });
    var syncedForename = computed({
      get: function get() {
        return props.forename;
      },
      set: function set(val) {
        emit('update:forename', val);
      }
    });
    var syncedSurname = computed({
      get: function get() {
        return props.surname;
      },
      set: function set(val) {
        emit('update:surname', val);
      }
    });
    return {
      surnameRules: surnameRules,
      nameRules: nameRules,
      syncedPatronymic: syncedPatronymic,
      syncedForename: syncedForename,
      syncedSurname: syncedSurname
    };
  }
});