export default defineComponent({
  name: 'CustomInfoAlert',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    link: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    btnProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    blockButton: {
      type: Boolean,
      default: false
    }
  }
});