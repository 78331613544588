import "core-js/modules/es.number.constructor.js";
import AppMenu from '~/core/components/AppMenu/AppMenu.vue';
import { ymGoal } from '~/core/utils/yandexReachGoal';
export default defineComponent({
  name: 'AppBar',
  components: {
    AppMenu: AppMenu
  },
  props: {
    prependIcon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    backTo: {
      type: Boolean,
      default: false
    },
    exact: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    borderless: {
      type: Boolean,
      default: false
    },
    hideOnScroll: {
      type: Boolean,
      default: true
    },
    extensionHeight: {
      type: [Number, String],
      default: 48
    }
  },
  emits: ['click:prepend-icon', 'click:append-icon'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useNuxtApp = useNuxtApp(),
      $device = _useNuxtApp.$device,
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var isMenuActive = ref(false);
    var appBar = ref(null);
    var appBarStyle = computed(function () {
      return {
        paddingRight: $device.isDesktop && $store.state.overlaysCount > 0 ? "".concat($vuetify.breakpoint.scrollBarWidth, "px") : '0'
      };
    });
    var prepend = computed(function () {
      if (props.prependIcon) {
        return props.prependIcon;
      }
      if (props.backTo) {
        return 'ui-icon-arrow-back';
      }
      return 'ui-icon-menu';
    });
    onMounted(function () {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/9993
      window.addEventListener('scroll', function () {
        if (!appBar.value) {
          return;
        }
        if (appBar.value.currentScroll < appBar.value.currentThreshold) {
          appBar.value.isActive = true;
        }
      });
    });
    function handleClickPrependIcon() {
      if (!props.backTo && !props.prependIcon) {
        ymGoal('clickMain');
        isMenuActive.value = true;
      }
      emit('click:prepend-icon');
    }
    return {
      isMenuActive: isMenuActive,
      appBarStyle: appBarStyle,
      prepend: prepend,
      handleClickPrependIcon: handleClickPrependIcon
    };
  }
});