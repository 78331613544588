import { infoClient } from '~/core/utils/clients/infoClient'

type SetSettingsParams = {
  isFemaleCalendarEnabled: boolean
}

export default {
  setSettings(params: SetSettingsParams) {
    return infoClient.post('/profile/female_calendar/settings/', params, { camelize: true })
  },
}
