import { defineStore } from 'pinia';
export default defineStore('mainSnackbar', function () {
  var isActive = ref(false);
  var text = ref('');
  var cancelText = ref('');
  var cancelHandler = ref(undefined);
  var dontCloseOnRouteChange = ref(false);
  function open(payload) {
    var _payload$text, _payload$cancelText;
    isActive.value = true;
    text.value = (_payload$text = payload.text) !== null && _payload$text !== void 0 ? _payload$text : '';
    cancelText.value = (_payload$cancelText = payload.cancelText) !== null && _payload$cancelText !== void 0 ? _payload$cancelText : '';
    cancelHandler.value = payload.cancelHandler;
    dontCloseOnRouteChange.value = payload.dontCloseOnRouteChange || false;
  }
  function close() {
    isActive.value = false;
    text.value = '';
    cancelText.value = '';
    cancelHandler.value = undefined;
    dontCloseOnRouteChange.value = false;
  }
  return {
    isActive: isActive,
    text: text,
    cancelText: cancelText,
    cancelHandler: cancelHandler,
    dontCloseOnRouteChange: dontCloseOnRouteChange,
    open: open,
    close: close
  };
});