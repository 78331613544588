import "core-js/modules/es.number.constructor.js";
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';
import { debounce } from 'lodash-es';
import { GALLERY_TOOLBAR_HEIGHT } from './constants';
var START_IMAGE_SCALE = 0.94;
export default defineComponent({
  name: 'FullscreenGalleryItemImg',
  components: {
    PinchScrollZoom: PinchScrollZoom
  },
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var expose = _ref.expose;
    var zoomer = ref(null);
    var innerScale = ref(START_IMAGE_SCALE);
    var renderKey = ref(props.width + props.height);
    watch(function () {
      return props.width || props.height;
    }, function () {
      changeRenderKey();
    });
    var isZoomApplied = function isZoomApplied() {
      return innerScale.value > 1.05;
    };
    var changeRenderKey = debounce(function () {
      renderKey.value = props.width + props.height;
    }, 30);
    function zoom(zoomFactor) {
      innerScale.value *= zoomFactor;
      if (innerScale.value < START_IMAGE_SCALE) {
        innerScale.value = START_IMAGE_SCALE;
      }
    }
    function handleScale(ev) {
      innerScale.value = ev.scale;
    }
    expose({
      zoom: zoom,
      isZoomApplied: isZoomApplied
    });
    return {
      GALLERY_TOOLBAR_HEIGHT: GALLERY_TOOLBAR_HEIGHT,
      START_IMAGE_SCALE: START_IMAGE_SCALE,
      renderKey: renderKey,
      innerScale: innerScale,
      handleScale: handleScale,
      zoomer: zoomer
    };
  }
});