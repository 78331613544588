import SupportContacts from '~/core/components/SupportContacts/SupportContacts.vue';
export default defineComponent({
  name: 'SupportContactsBottomSheet',
  components: {
    SupportContacts: SupportContacts
  },
  props: {
    support: {
      type: Object,
      required: true
    }
  }
});