import "core-js/modules/es.number.constructor.js";
var __default__ = defineComponent({
  name: 'UploadPlaceholder',
  props: {
    icon: {
      type: String,
      default: 'ui-icon-plus'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: 80
    },
    height: {
      type: [String, Number],
      default: 80
    },
    fromMedcard: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var borderStyle = computed(function () {
      return props.fromMedcard ? '1px solid #1A5DD0' : '1px dashed #1A5DD0';
    });
    function handleClick() {
      if (props.disabled) {
        return;
      }
      emit('click');
    }
    return {
      borderStyle: borderStyle,
      handleClick: handleClick
    };
  }
});
import { useCssVars as _useCssVars } from 'vue';
var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_vm, _setup) {
    return {
      "118b5eda": _vm.borderStyle
    };
  });
};
var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;