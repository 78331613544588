import BaseSheetItem from '~/core/components/Base/BaseSheetItem.vue';
export default defineComponent({
  name: 'BaseCheckbox',
  components: {
    BaseSheetItem: BaseSheetItem
  },
  inheritAttrs: false,
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    inputValue: {
      type: [Boolean, Array],
      default: null
    }
  },
  emits: ['change'],
  setup: function setup(props, _ref) {
    var expose = _ref.expose;
    var checkbox = ref(null);

    /** @public */
    function reset() {
      var _checkbox$value;
      (_checkbox$value = checkbox.value) === null || _checkbox$value === void 0 ? void 0 : _checkbox$value.reset();
    }

    /** @public */
    function resetValidation() {
      var _checkbox$value2;
      (_checkbox$value2 = checkbox.value) === null || _checkbox$value2 === void 0 ? void 0 : _checkbox$value2.resetValidation();
    }

    /** @public */
    function validate(force) {
      var _checkbox$value3;
      return (_checkbox$value3 = checkbox.value) === null || _checkbox$value3 === void 0 ? void 0 : _checkbox$value3.validate(force);
    }
    expose({
      reset: reset,
      resetValidation: resetValidation,
      validate: validate
    });
    return {
      checkbox: checkbox
    };
  }
});