import _typeof from "@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
import { debounce, isString } from 'lodash-es';
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue';
import SearchBar from '~/core/components/SearchBar/SearchBar.vue';
import EmptyPlug from '~/core/components/EmptyPlug/EmptyPlug.vue';
import { CONTAINER_WIDTH } from '~/core/constants';
import { isClearTextFieldClick } from '~/core/functions';
export default defineComponent({
  name: 'FullscreenSelect',
  components: {
    BaseFullscreenDialog: BaseFullscreenDialog,
    EmptyPlug: EmptyPlug,
    SearchBar: SearchBar
  },
  inheritAttrs: false,
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: ''
    },
    dialogLabel: {
      type: String,
      default: ''
    },
    searchLabel: {
      type: String,
      default: 'Найти'
    },
    selectClass: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    searchLoading: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ''
    },
    /**
     * Отключает автоматическую фильтрацию списка items.
     * Подходит для поиска посредством вызова api.
    * */
    disableAutoFiltering: {
      type: Boolean,
      default: false
    },
    debouncedSearch: {
      type: Boolean,
      default: false
    },
    searchDebounceTimeout: {
      type: Number,
      default: 300
    },
    hideOnSelect: {
      type: Boolean,
      default: true
    },
    hideActivator: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:searchQuery', 'update:isActive'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      expose = _ref.expose;
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var lazyIsActive = ref(false);
    var lazySearchQuery = ref('');
    var lazyValue = ref(null);
    var isInputFocused = ref(false);
    var isInputErrored = ref(false);
    var textField = ref(null);
    var arrowIconColor = computed(function () {
      if (isInputErrored.value) {
        return 'error';
      }
      if (isInputFocused.value) {
        return 'primary';
      }
      return undefined;
    });
    var displayedText = computed(function () {
      var _props$items$find;
      return props.returnObject && _typeof(lazyValue.value) === 'object' && lazyValue.value ? lazyValue.value[props.itemText] || '' : ((_props$items$find = props.items.find(function (item) {
        return item[props.itemValue] === lazyValue.value;
      })) === null || _props$items$find === void 0 ? void 0 : _props$items$find[props.itemText]) || '';
    });
    var innerSearchQuery = computed({
      get: function get() {
        return lazySearchQuery.value;
      },
      set: function set(val) {
        lazySearchQuery.value = val;
        if (props.debouncedSearch) {
          debouncedEmitUpdateSearch.value(val);
          return;
        }
        emit('update:searchQuery', val);
      }
    });
    var paddingBreakpoint = computed(function () {
      return CONTAINER_WIDTH + 30;
    });
    var searchBarStyle = computed(function () {
      if ($vuetify.breakpoint.width > paddingBreakpoint.value) {
        return {
          marginLeft: "-".concat($vuetify.breakpoint.scrollBarWidth / 2, "px")
        };
      }
      return {};
    });
    var filteredItems = computed(function () {
      return props.disableAutoFiltering ? props.items : props.items.filter(function (item) {
        var text = item[props.itemText];
        if (!lazySearchQuery.value || !isString(text)) {
          return true;
        }
        return text.toLowerCase().includes(lazySearchQuery.value.toLowerCase());
      }).sort(function (itemA, itemB) {
        var textA = itemA[props.itemText];
        var textB = itemB[props.itemText];
        if (!isString(textA) || !isString(textB) || !lazySearchQuery.value) {
          return 0;
        }
        var lowerSearch = lazySearchQuery.value.toLowerCase();
        var lowerA = textA.toLowerCase();
        var lowerB = textB.toLowerCase();
        var isAStartsWithSearch = lowerA.startsWith(lowerSearch);
        var isBStartsWithSearch = lowerB.startsWith(lowerSearch);
        if (lowerA === lowerB) {
          return 0;
        }
        if (isAStartsWithSearch && !isBStartsWithSearch) {
          return -1;
        }
        if (!isAStartsWithSearch && isBStartsWithSearch) {
          return 1;
        }
        return lowerA > lowerB ? 1 : -1;
      });
    });
    var selectedItemValue = computed(function () {
      return lazyValue.value && _typeof(lazyValue.value) === 'object' ? lazyValue.value[props.itemValue] : lazyValue.value;
    });
    watch(function () {
      return props.isActive;
    }, function (val) {
      lazyIsActive.value = val;
    }, {
      immediate: true
    });
    watch(function () {
      return props.searchDebounceTimeout;
    }, function (val) {
      debouncedEmitUpdateSearch.value.flush();
      debouncedEmitUpdateSearch.value = debounce(emit.bind(null, 'update:searchQuery'), val);
    });
    watch(function () {
      return props.searchQuery;
    }, function (val) {
      lazySearchQuery.value = val !== null && val !== void 0 ? val : '';
    }, {
      immediate: true
    });
    watch(function () {
      return props.value;
    }, function (val) {
      lazyValue.value = val;
    }, {
      immediate: true
    });
    watch(lazyValue, function (val) {
      if (val !== props.value) {
        emit('input', val);
      }
    });
    watch([isInputFocused, lazyIsActive], function () {
      if (!isInputFocused.value && !lazyIsActive.value) {
        var _textField$value$vali, _textField$value;
        isInputErrored.value = !((_textField$value$vali = (_textField$value = textField.value) === null || _textField$value === void 0 ? void 0 : _textField$value.validate(true)) !== null && _textField$value$vali !== void 0 ? _textField$value$vali : true);
      } else {
        isInputErrored.value = false;
      }
    });
    watch(lazyIsActive, function (val) {
      var _textField$value2;
      emit('update:isActive', val);
      if (val && !props.disableAutoFiltering) {
        innerSearchQuery.value = '';
      }
      (_textField$value2 = textField.value) === null || _textField$value2 === void 0 ? void 0 : _textField$value2.resetValidation();
    });
    var debouncedEmitUpdateSearch = ref(debounce(emit.bind(null, 'update:searchQuery'), props.searchDebounceTimeout));
    function handleSelect(index) {
      var selectedItem = filteredItems.value[index];
      lazyValue.value = props.returnObject ? selectedItem : selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem[props.itemValue];
      if (props.hideOnSelect) {
        lazyIsActive.value = false;
      }
    }
    function isItemSelected(index) {
      var _filteredItems$value$;
      return ((_filteredItems$value$ = filteredItems.value[index]) === null || _filteredItems$value$ === void 0 ? void 0 : _filteredItems$value$[props.itemValue]) === selectedItemValue.value;
    }
    function handleActivatorClick(ev) {
      if (!isClearTextFieldClick(ev)) {
        return;
      }
      lazyIsActive.value = true;
    }
    expose({
      validate: function validate() {
        var _textField$value$vali2, _textField$value3;
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        isInputErrored.value = !((_textField$value$vali2 = (_textField$value3 = textField.value) === null || _textField$value3 === void 0 ? void 0 : _textField$value3.validate.apply(_textField$value3, args)) !== null && _textField$value$vali2 !== void 0 ? _textField$value$vali2 : true);
      },
      resetValidation: function resetValidation() {
        var _textField$value4;
        isInputErrored.value = false;
        (_textField$value4 = textField.value) === null || _textField$value4 === void 0 ? void 0 : _textField$value4.resetValidation();
      },
      reset: function reset() {
        var _textField$value5;
        return (_textField$value5 = textField.value) === null || _textField$value5 === void 0 ? void 0 : _textField$value5.reset();
      }
    });
    return {
      textField: textField,
      isInputErrored: isInputErrored,
      arrowIconColor: arrowIconColor,
      displayedText: displayedText,
      isInputFocused: isInputFocused,
      lazyIsActive: lazyIsActive,
      innerSearchQuery: innerSearchQuery,
      lazyValue: lazyValue,
      paddingBreakpoint: paddingBreakpoint,
      searchBarStyle: searchBarStyle,
      filteredItems: filteredItems,
      handleSelect: handleSelect,
      isItemSelected: isItemSelected,
      handleActivatorClick: handleActivatorClick
    };
  }
});