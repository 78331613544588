import type { Context } from '@nuxt/types'
import { CORE_ACTIONS, MEDCARD_MUTATIONS } from '~/core/constants'
import { FAMILY_PROFILE_UUID_STORAGE_KEY } from '~/core/constants/sessionStorage'
import type { FamilyUser } from '~/core/types/family'
import { isDeeplinkOnlyPage } from '~/core/functions'

export default async function({ from, route, store, error, $device, $sentry, $captureException }: Context) {
  try {
    if (route.name?.includes('dental-articles') || isDeeplinkOnlyPage(route, $device, $sentry)) {
      return
    }

    const meta = route.meta?.[0] || {} // NOTE: Используется для обновления информации о семейных профилях для определенных роутов

    if (meta.useFamily) {
      await store.dispatch(CORE_ACTIONS.FETCH_FAMILY)

      const uuid = route.query.uuid
      const sessionStorageUuid = sessionStorage.getItem(FAMILY_PROFILE_UUID_STORAGE_KEY)

      if (uuid && store.state.family.find((member: FamilyUser) => member.uuid === uuid)) {
        store.commit(MEDCARD_MUTATIONS.SET_FAMILY_PROFILE_UUID, uuid as string)
      } else if (
        !from.name?.includes('medcard') ||
        !route.name?.includes('medcard') ||
        !sessionStorageUuid ||
        !store.state.family.find((member: FamilyUser) => member.uuid === sessionStorageUuid)
      ) {
        store.commit(
          MEDCARD_MUTATIONS.SET_FAMILY_PROFILE_UUID,
          store.state.family.find((member: FamilyUser) => member.isMain).uuid,
        )
      }
    }
  } catch (e) {
    $captureException(e, {
      message: 'Ошибка при запросе семейных профилей',
    })

    error(e)
  }
}
