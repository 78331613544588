import "core-js/modules/web.timers.js";
import { useClipboard } from '@vueuse/core';
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar';
import useMainSnackbarStore from '~/stores/useMainSnackbarStore';
export var useLinkShare = function useLinkShare() {
  var _useNuxtApp = useNuxtApp(),
    $device = _useNuxtApp.$device;
  var _useClipboard = useClipboard(),
    copy = _useClipboard.copy;
  var $snackbar = useMainSnackbarStore();
  function handleShare(message) {
    if ($device.isDesktop || !navigator.share) {
      // NOTE: Clipboard API Apple ждёт промис когда мы пытается что-то записать в буфер обмена.
      // В целом можно решить через использование макротаски
      setTimeout(function () {
        return copy(message);
      }, 0);
      $snackbar.open(CORE_SNACKBAR.LINK_COPIED);
      return;
    }

    // Пустой catch чтобы не отправлять в sentry unhandled ошибки
    navigator.share({
      text: message
    }).catch(function () {});
  }
  return {
    handleShare: handleShare
  };
};