/**
 * Позволяет проверить доступен ли App на текущем устройстве
 * */
import type { Context } from '@nuxt/types'
import { isValidAndroidVersion } from './isValidAndroidVersion'
import { isValidIosVersion } from './isValidIosVersion'

export const isAppSupported = ({ $device, $sentry }: Pick<Context, '$device' | '$sentry'>) => {
  if (!$device.isMobileOrTablet) {
    return false
  }

  if ($device.isIos) {
    return isValidIosVersion({ $device, $sentry })
  }

  if ($device.isAndroid) {
    return isValidAndroidVersion({ $device, $sentry })
  }

  $sentry.captureMessage('Не удалось определить мобильное устройство')

  return true
}
