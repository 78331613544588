import "core-js/modules/es.object.to-string.js";
import { defineStore } from 'pinia';
var DEFAULT_OPTIONS = {
  persistent: false,
  isDanger: false,
  confirmHandler: undefined,
  closeHandler: undefined,
  title: '',
  text: '',
  image: '',
  closeText: 'Понятно',
  confirmText: '',
  afterText: undefined
};
export default defineStore('mainDialog', function () {
  var isActive = ref(false);
  var persistent = ref(false);
  var isDanger = ref(false);
  var confirmHandler = ref();
  var closeHandler = ref();
  var title = ref('');
  var text = ref('');
  var image = ref('');
  var closeText = ref('');
  var confirmText = ref('');
  var afterText = ref();
  function open(options) {
    return new Promise(function (resolve) {
      var _options$isDanger, _options$persistent, _options$closeHandler, _options$confirmHandl, _options$title, _options$text, _options$image, _options$closeText, _options$confirmText, _options$afterText;
      isActive.value = true;
      isDanger.value = (_options$isDanger = options.isDanger) !== null && _options$isDanger !== void 0 ? _options$isDanger : DEFAULT_OPTIONS.isDanger;
      persistent.value = (_options$persistent = options.persistent) !== null && _options$persistent !== void 0 ? _options$persistent : DEFAULT_OPTIONS.persistent;
      closeHandler.value = (_options$closeHandler = options.closeHandler) !== null && _options$closeHandler !== void 0 ? _options$closeHandler : DEFAULT_OPTIONS.closeHandler;
      confirmHandler.value = (_options$confirmHandl = options.confirmHandler) !== null && _options$confirmHandl !== void 0 ? _options$confirmHandl : DEFAULT_OPTIONS.confirmHandler;
      title.value = (_options$title = options.title) !== null && _options$title !== void 0 ? _options$title : DEFAULT_OPTIONS.title;
      text.value = (_options$text = options.text) !== null && _options$text !== void 0 ? _options$text : DEFAULT_OPTIONS.text;
      image.value = (_options$image = options.image) !== null && _options$image !== void 0 ? _options$image : DEFAULT_OPTIONS.image;
      closeText.value = (_options$closeText = options.closeText) !== null && _options$closeText !== void 0 ? _options$closeText : DEFAULT_OPTIONS.closeText;
      confirmText.value = (_options$confirmText = options.confirmText) !== null && _options$confirmText !== void 0 ? _options$confirmText : DEFAULT_OPTIONS.confirmText;
      afterText.value = (_options$afterText = options.afterText) !== null && _options$afterText !== void 0 ? _options$afterText : DEFAULT_OPTIONS.afterText;
      if (options.confirmText) {
        confirmHandler.value = function () {
          var _options$confirmHandl2;
          (_options$confirmHandl2 = options.confirmHandler) === null || _options$confirmHandl2 === void 0 ? void 0 : _options$confirmHandl2.call(options);
          resolve(true);
        };
        closeHandler.value = function () {
          var _options$closeHandler2;
          (_options$closeHandler2 = options.closeHandler) === null || _options$closeHandler2 === void 0 ? void 0 : _options$closeHandler2.call(options);
          resolve(false);
        };
      } else {
        closeHandler.value = function () {
          var _options$closeHandler3;
          (_options$closeHandler3 = options.closeHandler) === null || _options$closeHandler3 === void 0 ? void 0 : _options$closeHandler3.call(options);
          resolve(true);
        };
      }
    });
  }
  function close() {
    isActive.value = false;
    isDanger.value = DEFAULT_OPTIONS.isDanger;
    persistent.value = DEFAULT_OPTIONS.persistent;
    closeHandler.value = DEFAULT_OPTIONS.closeHandler;
    confirmHandler.value = DEFAULT_OPTIONS.confirmHandler;
    title.value = DEFAULT_OPTIONS.title;
    text.value = DEFAULT_OPTIONS.text;
    image.value = DEFAULT_OPTIONS.image;
    closeText.value = DEFAULT_OPTIONS.closeText;
    confirmText.value = DEFAULT_OPTIONS.confirmText;
    afterText.value = DEFAULT_OPTIONS.afterText;
  }
  return {
    isActive: isActive,
    isDanger: isDanger,
    persistent: persistent,
    confirmHandler: confirmHandler,
    closeHandler: closeHandler,
    title: title,
    text: text,
    image: image,
    closeText: closeText,
    confirmText: confirmText,
    afterText: afterText,
    open: open,
    close: close
  };
});