import "core-js/modules/es.function.name.js";
import { AppSmartBanner } from '~/core/components';
import RulesAgree from '~/core/components/RulesAgree/RulesAgree.vue';
import { MainDialog, MainSnackbar } from '~/core/components/dialogs';
import TownSelect from '~/core/components/TownSelect/TownSelect.vue';
import EnvironmentBanner from '~/core/components/EnvironmentBanner/EnvironmentBanner.vue';
export default defineComponent({
  name: 'MobileLayout',
  components: {
    EnvironmentBanner: EnvironmentBanner,
    TownSelect: TownSelect,
    AppSmartBanner: AppSmartBanner,
    MainDialog: MainDialog,
    MainSnackbar: MainSnackbar,
    RulesAgree: RulesAgree
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $device = _useNuxtApp.$device,
      $store = _useNuxtApp.$store;
    var $route = useRoute();
    var shouldShowRulesAgreement = computed(function () {
      return $route.name !== 'authorization';
    });
    var isShowAppSmartBanner = computed(function () {
      return $device.isMobile && $store.state.profile.phone;
    });
    var isTelemedPage = computed(function () {
      return $route.name === 'appointments-id-telemed';
    });
    return {
      shouldShowRulesAgreement: shouldShowRulesAgreement,
      isShowAppSmartBanner: isShowAppSmartBanner,
      isTelemedPage: isTelemedPage
    };
  }
});