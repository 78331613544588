import BottomControls from '~/core/components/BottomControls/BottomControls.vue';

/**
 * Компонент обёртка вокруг BottomControls с двумя/одной кнопкой */
export default defineComponent({
  name: 'BottomControlsButtons',
  components: {
    BottomControls: BottomControls
  },
  props: {
    submitProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cancelProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    onlySubmit: {
      type: Boolean,
      default: false
    },
    column: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: 'подтвердить'
    },
    cancelText: {
      type: String,
      default: 'отменить'
    },
    submitIcon: {
      type: String,
      default: ''
    },
    cancelIcon: {
      type: String,
      default: ''
    }
  },
  emits: ['click:submit', 'click:cancel'],
  setup: function setup(_, _ref) {
    var emit = _ref.emit;
    function emitClickSubmit() {
      emit('click:submit');
    }
    function emitClickCancel() {
      emit('click:cancel');
    }
    return {
      emitClickSubmit: emitClickSubmit,
      emitClickCancel: emitClickCancel
    };
  }
});