import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject

import { AxiosError } from 'axios';
import { composeSentryContext } from '~/core/functions';
import { HttpStatusCode } from '~/core/enums';
var safeStatusCodes = [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden, HttpStatusCode.NotFound];
var captureException = function captureException(_ref, inject) {
  var $sentry = _ref.$sentry;
  var callback = function callback(error, tags) {
    if (error instanceof AxiosError && error.response) {
      if (safeStatusCodes.includes(error.response.status)) {
        return '';
      }
      if (!window.navigator.onLine || error.response.status === 0 || error.message === 'Network Error' || error.code === 'ERR_NETWORK') {
        return '';
      }
      if (error.message === 'Request aborted' || error.code === 'ECONNABORTED') {
        return '';
      }
    }
    return $sentry.captureException(error, composeSentryContext({
      error: error,
      tags: tags !== null && tags !== void 0 ? tags : {}
    }));
  };
  inject('captureException', callback);
};
export default captureException;