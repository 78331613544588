export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Divider',
  props: {
    color: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    arrow: {
      type: Boolean
    }
  }
});