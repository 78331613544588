import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
export default defineComponent({
  name: 'BottomControls',
  props: {
    row: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var slots = _ref.slots;
    var isExtended = ref(false);
    onMounted(function () {
      var _slots$default;
      // NOTE: приходится создавать эту переменную, чтобы определять высоту статично позиционируемого элемента
      isExtended.value = !props.row && Number((_slots$default = slots.default) === null || _slots$default === void 0 ? void 0 : _slots$default.call(slots).filter(function (v) {
        return v.elm instanceof Element;
      }).length) >= 2;
    });
    return {
      isExtended: isExtended
    };
  }
});