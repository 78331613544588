import { render, staticRenderFns } from "./ImageAiProcessing.vue?vue&type=template&id=3e0bea1d&scoped=true"
import script from "./ImageAiProcessing.vue?vue&type=script&setup=true&lang=ts"
export * from "./ImageAiProcessing.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ImageAiProcessing.vue?vue&type=style&index=0&id=3e0bea1d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e0bea1d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSwitch: require('/builds/private/medtochka-frontend/core/components/Base/BaseSwitch.vue').default,BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default})
