import type { Context } from '@nuxt/types'
import axios from 'axios'
import isJSON from 'validator/lib/isJSON'
import { getDataSet, mergeSentInterface } from '~/core/functions/fingerprints'
import getCSRFToken from '~/core/functions/getCSRFToken'
import { FINGERPRINT_URL } from '~/core/constants'

const sendUserInfo = async ($captureException: Context['$captureException']) => {
  try {
    let userInfo = await getDataSet()
    const mergedUserInfo = mergeSentInterface({
      resultData: isJSON(userInfo) ? JSON.parse(userInfo) : {},
    })

    userInfo = JSON.stringify(mergedUserInfo)

    await axios.post('', userInfo, {
      baseURL: FINGERPRINT_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': getCSRFToken(),
      },
    })
  } catch (e) {
    $captureException(e, {
      method: 'fingerprint_middleware',
    })
  }
}

export default function({ $captureException }: Context) {
  // Запрос не должен блочить переход между страницами
  sendUserInfo($captureException)
}
