import { useVModel } from '@vueuse/core';
import { VDialog, VNavigationDrawer } from 'vuetify/lib/components';
import AppToolbar from '~/core/components/AppToolbar/AppToolbar.vue';
import { CORE_MUTATIONS } from '~/core/constants';
import AppSmartBanner from '~/core/components/AppSmartBanner/AppSmartBanner.vue';
export default defineComponent({
  name: 'BaseFullscreenDialog',
  components: {
    AppSmartBanner: AppSmartBanner,
    AppToolbar: AppToolbar
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    backTo: {
      type: Boolean,
      default: false
    },
    hideCloseBtn: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    borderlessTitle: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    pageBorder: {
      type: Boolean,
      default: false
    },
    fixedToolbar: {
      type: Boolean,
      default: false
    },
    toolbarAppendIcon: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    showSmartBanner: {
      type: Boolean,
      default: false
    },
    persistentScroll: {
      type: Boolean,
      default: false
    },
    preventFromClose: {
      type: Boolean,
      default: false
    },
    drawer: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click:prepend-icon', 'click:append-icon', 'input'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var isActive = useVModel(props);
    watch(isActive, function (val, prev) {
      if (val) {
        $store.commit(CORE_MUTATIONS.INC_OVERLAYS_COUNT);
      } else if (prev && !val) {
        $store.commit(CORE_MUTATIONS.DEC_OVERLAYS_COUNT);
      }
    }, {
      immediate: true
    });
    onBeforeUnmount(function () {
      if (isActive.value) {
        $store.commit(CORE_MUTATIONS.CLEAR_OVERLAYS_COUNT);
      }
    });
    function handleClickClose() {
      emit('click:prepend-icon');
      if (props.preventFromClose) {
        return;
      }
      emitInput(false);
    }
    function emitClickAppendIcon() {
      emit('click:append-icon');
    }
    function emitInput(ev) {
      isActive.value = ev;
    }
    return {
      isActive: isActive,
      handleClickClose: handleClickClose,
      emitClickAppendIcon: emitClickAppendIcon,
      emitInput: emitInput,
      VDialog: VDialog,
      VNavigationDrawer: VNavigationDrawer
    };
  }
});