import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.trim.js";
import { repeat } from 'lodash-es';
import { BottomControlsButtons } from '~/core/components';
import { numberDeclension } from '~/core/constants/wordForms';
import { pluralize } from '~/core/utils/pluralize';
import { MailStatus } from '~/features/Profile/enums';
export default defineComponent({
  name: 'EnterCode',
  components: {
    BottomControlsButtons: BottomControlsButtons
  },
  props: {
    mail: {
      type: String,
      required: true
    },
    codeTimer: {
      type: String,
      default: ''
    },
    errorCode: {
      type: String,
      default: ''
    },
    codeLength: {
      type: Number,
      default: 4
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    confirmOnFill: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    dailyLimit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['send:code', 'confirm:mail', 'enter', 'input'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var form = ref(null);
    var isFormCorrect = ref(false);
    var code = ref('');
    var rules = [function () {
      return props.errorCode !== MailStatus.Expired || 'Время для ввода кода истекло, попробуйте еще раз';
    }, function () {
      return props.errorCode !== MailStatus.AttemptsOut || 'Вы 3 раза ввели код неверно, отправьте новый';
    }, function () {
      return props.errorCode !== MailStatus.WrongCode || 'Неверный код';
    }, function () {
      return props.errorCode !== MailStatus.Invalid || 'Неверный код';
    }, function (v) {
      if (props.confirmOnFill) {
        return true;
      }
      var regex = new RegExp("\\d{".concat(props.codeLength, "}"));
      var errorText = "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 ".concat(pluralize(props.codeLength, numberDeclension), " \u043A\u043E\u0434\u0430 \u0438\u0437 \u043F\u0438\u0441\u044C\u043C\u0430");
      return v.trim().length === props.codeLength && regex.test(v.trim()) || errorText;
    }];
    var inputMask = computed(function () {
      return repeat('#', props.codeLength);
    });
    var isDailyLimit = computed(function () {
      return props.dailyLimit || props.errorCode === MailStatus.DailyLimit;
    });
    watch(function () {
      return props.errorCode;
    }, function () {
      form.value.validate();
    });
    watch(code, function (val) {
      if (!props.confirmOnFill) {
        return;
      }
      if (val.length === props.codeLength && form.value.validate()) {
        emitConfirmMail(val.trim());
      }
    });
    function handleSendCode() {
      if (isDailyLimit.value) {
        return;
      }
      emit('send:code');
      code.value = '';
    }
    function emitConfirmMail(val) {
      emit('confirm:mail', val);
    }
    function emitEnter(val) {
      emit('enter', val);
    }
    function emitInput(val) {
      emit('input', val);
    }
    return {
      form: form,
      isFormCorrect: isFormCorrect,
      code: code,
      rules: rules,
      inputMask: inputMask,
      isDailyLimit: isDailyLimit,
      handleSendCode: handleSendCode,
      emitConfirmMail: emitConfirmMail,
      emitEnter: emitEnter,
      emitInput: emitInput
    };
  }
});