export default defineComponent({
  name: 'MediaRules',
  props: {
    text: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean
    }
  },
  emits: ['input']
});