import { infoClient } from '~/core/utils/clients/infoClient'
import { masterClient } from '~/core/utils/clients/masterClient'
import type { RateType, SkipReason } from '~/features/Rates/enums'
import type { RateDetailsDtoV2, RateScoreDto } from '~/features/Rates/api/chat.api.types'
import { RateStatusV2 } from '~/features/Rates/api/chat.api.enums'

const RESOURCE = '/rates'

export type RateExpectedDto = {
  appointmentId: number
  avatarPath: string
  datetimeVisitUtc: string
  doctorIdProdoctorov: number | null
  isDoctorRateRequired: boolean
  isLpuRateRequired: boolean
  isDoctorSupplementRequired: boolean
  isLpuSupplementRequired: boolean
  lpuAddress: string
  lpuAvatarPath: string
  lpuIdProdoctorov: number
  lpuName: string
  name: string
  speciality: string
}

export type RateSupplementPreviewDto = {
  id: number
  text: string
  dtCreated: string
  status: RateStatusV2
  hasModeratorMessage: boolean
  messagesCount: number | null
  score: RateScoreDto
}

export type RatePreviewDto = {
  name: string
  avatar: string
  rateType: RateType
  id: number
  messagesCount: number | null
  dtCreated: string
  status: RateStatusV2
  info: string,
  mainText: string,
  negativeText: string,
  positiveText: string,
  hasModeratorMessage: boolean
  supplements: RateSupplementPreviewDto[] | null
  score: RateScoreDto
}

export type RatesDto = Record<RateStatusV2, RatePreviewDto[]>

export type RateCommentDto = {
  comment: string
  commentPos: string
  commentNeg: string
  textHelp: string
  ignoreBadWords: boolean
  id: number
}

type RateDocumentDto = {
  id: number | string
  imgS: string
  imgL?: string
  status?: string
  preview?: string
}

export type RateDocumentsDto = {
  id: number
  help: {
    text: string
    detail: string
  }
  images: RateDocumentDto[]
}

export default {
  get() {
    return masterClient.get<RatesDto>(`${RESOURCE}/v2/`, {
      camelize: true,
      camelizeProcessor:
        (key, convert) => Object.values(RateStatusV2).includes(key as RateStatusV2) ? key : convert(key),
    })
  },
  getWaitingRates(payload?: { limit?: number, type?: RateType}) {
    return masterClient.get<RateExpectedDto[]>(`${RESOURCE}/v2/waiting/`, {
      params: payload,
      camelize: true,
    })
  },
  getById(type: string, id: string) {
    return masterClient.get<RateDetailsDtoV2>(`${RESOURCE}/v2/${type}/${id}/`, { camelize: true })
  },
  getComment(type: string, id: string) {
    return masterClient.get<RateCommentDto>(`${RESOURCE}/${type}/${id}/comment/`, { camelize: true })
  },
  getDocument(type: string, id: string) {
    return masterClient.get<RateDocumentsDto>(`${RESOURCE}/${type}/${id}/document/`, { camelize: true })
  },
  getSupplementDocument(type: string, id: number) {
    return masterClient.get<RateDocumentsDto>(`${RESOURCE}/v2/supplement/${type}/${id}/document/`, { camelize: true })
  },
  getPublishedRateUrl(type: string, id: string) {
    return masterClient.get<{ similarRateUrl: string }>(
      `${RESOURCE}/${type}/${id}/similar/`,
      { camelize: true },
    )
  },
  postComment({ type, id }: { type: string, id: string }, payload: Omit<RateCommentDto, 'id' | 'textHelp' | 'ignoreBadWords'>) {
    return masterClient.post(`${RESOURCE}/${type}/${id}/comment/`, payload, { camelize: true })
  },
  postDocument({ type, id }: { type: string, id: string }, payload: string) {
    return masterClient.post<RateDocumentDto>(
      `${RESOURCE}/${type}/${id}/document/`,
      { image: payload },
      { camelize: true },
    )
  },
  postSupplementDocument({ type, id }: { type: string, id: number }, payload: string) {
    return masterClient.post<{ id: number }>(
      `${RESOURCE}/v2/supplement/${type}/${id}/document/add/`,
      { image: payload },
      { camelize: true },
    )
  },
  postComplete({ type, id }: { type: string, id: string }, ignoreBadWords: boolean) {
    return masterClient.post(
      `${RESOURCE}/${type}/${id}/complete/`,
      { ignoreBadWords },
      { camelize: true },
    )
  },
  postSupplementComplete(
    query: { type: string, id: number },
    payload: { supplementText: string, ignoreBadWords: boolean},
  ) {
    return masterClient.post(
      `${RESOURCE}/v2/supplement/${query.type}/${query.id}/complete/`,
      payload,
      { camelize: true },
    )
  },
  deleteDocument({ type, id }: { type: string, id: string }, documentId: number | string) {
    return masterClient.delete<RateDocumentDto>(
      `${RESOURCE}/${type}/${id}/document/${documentId}/`,
      { camelize: true },
    )
  },
  deleteSupplementDocument({ type, id }: { type: string, id: number }, documentId: number | string) {
    return masterClient.delete(
      `${RESOURCE}/v2/supplement/${type}/${id}/document/${documentId}/`,
      { camelize: true },
    )
  },
  patchScore({ type, id }: { type: string, id: string }, payload: Record<string, number>) {
    return masterClient.patch(`${RESOURCE}/${type}/${id}/`, payload, { camelize: true })
  },
  patchSupplementScore({ type, id }: { type: string, id: number }, payload: Record<string, number>) {
    return masterClient.patch(`${RESOURCE}/v2/supplement/${type}/${id}/ratings/`, payload, { camelize: true })
  },
  skipRate(appointmentId: number, reason: SkipReason) {
    return infoClient.post(
      `/appointments/${appointmentId}/skip_rate/`,
      { rateSkipReason: reason },
      { camelize: true },
    )
  },
  publish(type: string, id: string) {
    return masterClient.post(`/rates/${type}/${id}/publish_agreement/`, {}, { camelize: true })
  },
  publishSupplement(type: string, id: number) {
    return masterClient.post(`/rates/v2/supplement/${type}/${id}/publish_agreement/`, {}, { camelize: true })
  },
  getRatesCount() {
    return masterClient.get<{ doctorRatesCount: number, lpuRatesCount: number }>(
      `${RESOURCE}/count/`,
      { camelize: true },
    )
  },
}
