import { rulesGenerators } from '~/core/utils/rulesGenerators'

const rules = [
  (v: string) => v === null || !v || (/^[\u0410-\u042F\u0430-\u044F\u0401\u0451\u00EB\u00CB\- ]+$/).test(v.trim()) || !v.trim() || 'Используйте только кириллицу',
  (v: string) => v === null || v.trim().length <= 30 || 'Должно быть не больше 30 символов',
]

const patronymicRules = [
  ...rules,
  (v: string) => !v || v?.trim()?.length > 1 || 'Должно быть от 2 до 30 символов',
]

export const patronymicOptionalRules = [
  rulesGenerators.required('Заполните поле или отметьте, что отчества нет'),
  ...patronymicRules,
]

export const nameRules = [
  rulesGenerators.required(),
  ...patronymicRules,
]

export const surnameRules = [
  rulesGenerators.required(),
  ...rules,
]
