import FullscreenSelect from '~/core/components/FullscreenSelect/FullscreenSelect.vue';
import { useTownSearch } from '~/core/composables';
export default defineComponent({
  name: 'FullscreenTownSelect',
  components: {
    FullscreenSelect: FullscreenSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    rules: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hideOnSelect: {
      type: Boolean,
      default: true
    },
    hideActivator: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:isActive'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      expose = _ref.expose;
    var lazyValue = ref(null);
    var searchQuery = ref('');
    var select = ref(null);
    var lazyIsActive = ref(false);
    var _useTownSearch = useTownSearch({
        searchQuery: searchQuery
      }),
      towns = _useTownSearch.towns,
      loading = _useTownSearch.loading,
      forceSearch = _useTownSearch.forceSearch;
    watch(function () {
      return props.value;
    }, function (val) {
      lazyValue.value = val;
    }, {
      immediate: true,
      deep: true
    });
    watch(lazyValue, function (val) {
      if (val === props.value) {
        return;
      }
      emit('input', val);
    }, {
      deep: true
    });
    watch(function () {
      return props.isActive;
    }, function (val) {
      lazyIsActive.value = val;
    }, {
      immediate: true
    });
    watch(lazyIsActive, function (val) {
      emit('update:isActive', val);
      if (val) {
        if (!searchQuery.value) {
          forceSearch('');
        }
        searchQuery.value = '';
      }
    });
    expose({
      validate: function validate() {
        var _select$value;
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        return (_select$value = select.value) === null || _select$value === void 0 ? void 0 : _select$value.validate.apply(_select$value, args);
      },
      resetValidation: function resetValidation() {
        var _select$value2;
        return (_select$value2 = select.value) === null || _select$value2 === void 0 ? void 0 : _select$value2.resetValidation();
      },
      reset: function reset() {
        var _select$value3;
        return (_select$value3 = select.value) === null || _select$value3 === void 0 ? void 0 : _select$value3.reset();
      }
    });
    return {
      lazyIsActive: lazyIsActive,
      select: select,
      lazyValue: lazyValue,
      searchQuery: searchQuery,
      towns: towns,
      loading: loading
    };
  }
});