import "core-js/modules/es.number.constructor.js";
import { videoReset } from '~/features/Medcard/functions/videoReset';
var PLACEHOLDER_IMG_WIDTH = 150;
var PLACEHOLDER_IMG_HEIGHT = 150;
var PLACEHOLDER_LARGE_IMG_WIDTH = 175;
var PLACEHOLDER_LARGE_IMG_HEIGHT = 175;
export default defineComponent({
  name: 'ResultsPlaceholder',
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    imgBig: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    videoSrc: {
      type: String,
      default: ''
    },
    /**
     * В основном по дизайну используется для отображения ошибок на экране. При положительном значении этого prop'a,
     * компонент будет занимать всю высоту экрана и центрировать контент относительного всего экрана.
     * ВАЖНО! Чтобы у родительских элементов не был задан overflow
     * */
    pagePlaceholder: {
      type: Boolean,
      default: false
    },
    /**
     * При положительном значении prop'a pagePlaceholder изменяет отступ для центрирования по всей высоте экрана.
     * Если prop не задан, то вместо него используется $vuetify.application.top
     * */
    toolbarHeight: {
      type: Number,
      default: null
    }
  },
  emits: ['submit'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var innerToolbarHeight = computed(function () {
      var _props$toolbarHeight;
      return (_props$toolbarHeight = props.toolbarHeight) !== null && _props$toolbarHeight !== void 0 ? _props$toolbarHeight : $vuetify.application.top;
    });
    var imgSizes = computed(function () {
      if (props.imgBig) {
        return {
          width: PLACEHOLDER_LARGE_IMG_WIDTH,
          height: PLACEHOLDER_LARGE_IMG_HEIGHT
        };
      }
      return {
        width: PLACEHOLDER_IMG_WIDTH,
        height: PLACEHOLDER_IMG_HEIGHT
      };
    });
    var imgProps = computed(function () {
      return {
        src: props.imgSrc,
        contain: true,
        width: imgSizes.value.width,
        'min-width': imgSizes.value.width,
        'max-width': imgSizes.value.width,
        height: imgSizes.value.height,
        'min-height': imgSizes.value.height,
        'max-height': imgSizes.value.height
      };
    });
    onBeforeUpdate(function () {
      if (props.videoSrc) {
        videoReset();
      }
    });
    function emitSubmit() {
      emit('submit');
    }
    return {
      innerToolbarHeight: innerToolbarHeight,
      imgSizes: imgSizes,
      imgProps: imgProps,
      emitSubmit: emitSubmit
    };
  }
});