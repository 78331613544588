export var useGoBack = function useGoBack(backRoute) {
  var $route = useRoute();
  var $router = useRouter();
  return function () {
    if ($route.params.from) {
      $router.push($route.params.from);
    } else if (backRoute) {
      $router.push(backRoute);
    } else {
      $router.back();
    }
  };
};