import { tryOnBeforeUnmount, tryOnMounted } from '@vueuse/core';
export var useMultipleTouchActive = function useMultipleTouchActive() {
  var isMultipleTouchActive = ref(false);
  var touchMoveListener = function touchMoveListener(ev) {
    isMultipleTouchActive.value = ev.touches.length >= 2;
  };
  var touchEndListener = function touchEndListener() {
    isMultipleTouchActive.value = false;
  };
  tryOnMounted(function () {
    document.addEventListener('touchmove', touchMoveListener);
    document.addEventListener('touchend', touchEndListener);
  });
  tryOnBeforeUnmount(function () {
    document.removeEventListener('touchmove', touchMoveListener);
    document.removeEventListener('touchend', touchEndListener);
  });
  return isMultipleTouchActive;
};