import { AppBar } from '~/core/components/desktop';
import Copyright from '~/core/components/Copyright/Copyright.vue';
import { MainDialog, MainSnackbar } from '~/core/components/dialogs';
import EnvironmentBanner from '~/core/components/EnvironmentBanner/EnvironmentBanner.vue';
export default defineComponent({
  name: 'DesktopLayout',
  components: {
    EnvironmentBanner: EnvironmentBanner,
    AppBar: AppBar,
    Copyright: Copyright,
    MainDialog: MainDialog,
    MainSnackbar: MainSnackbar
  },
  setup: function setup() {
    var route = useRoute();
    // высота такая же, как у AppBar, чтобы форма авторизации была ровно посередине
    var footerHeight = 64;
    var isAppBarVisible = computed(function () {
      return route.query.partners === undefined;
    });
    return {
      isAppBarVisible: isAppBarVisible,
      footerHeight: footerHeight
    };
  }
});