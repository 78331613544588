export default defineComponent({
  name: 'ExpansionPanel',
  props: {
    background: {
      type: String,
      default: ''
    },
    flat: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var isOpen = computed(function () {
      return !props.isActive || 0;
    });
    return {
      isOpen: isOpen
    };
  }
});