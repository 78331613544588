import { masterClient } from '~/core/utils/clients/masterClient'
import type { AgreementStatus, MedcardStatus } from '~/features/Profile/enums'
import type { Country } from '~/features/Authorization/api/auth.api'

const RESOURCE = '/me/'

export type MeDto = {
  id: number,
  featureFlags: Record<string, boolean>
  phone: string,
  townName: string,
  townKey: string,
  townId: number,
  townConfirmed: boolean,
  rulesAgreementStatus: AgreementStatus
  medcardStatus: MedcardStatus,
  unreadNotificationsCount: number,
  medcardActiveSessionsCount: number
  country: Country | null
}

export default {
  get(token: string | undefined) {
    return masterClient.get<MeDto>(RESOURCE, {
      params: { token },
      camelize: true,
    })
  },
}
