import { medcardClient } from '~/core/utils/clients/medcardClient'
import { infoClient } from '~/core/utils/clients/infoClient'
import type { TreatmentPlanRoutes, TreatmentPlanTaskType } from '~/features/Medcard/enums'

const RESOURCE = '/treatment_plans'

export type TreatmentPlanDto = {
  treatmentPlanId: number,
  doctorName: string,
  specialityName: string,
  avatarPath: string,
  dtVisit: DateISO
}

export type TreatmentPlanDetailsDto = {
  specialityName: string,
  tasks: Array<{
    taskType: TreatmentPlanTaskType,
    isCompleted: boolean,
    additionalInfo: string,
    routingData: {
      route: TreatmentPlanRoutes,
      routeDocumentGroupId: string,
      routeAppointmentId: string,
      routeAppointmentRepeatScheduleDate: DateISO
    } | null
  }>
}

export type UnconfirmedTreatmentPlanDto = {
  id: number
  lpuName: string
  dtCreated: DateTimeISO
  expireInDays: number
}

export const TreatmentPlanApi = {
  getTreatmentPlansCount(): Promise<{ data: { totalCount: number, activeCount: number } }> {
    return medcardClient.get(`${RESOURCE}/count/`, { camelize: true })
  },
  getTreatmentPlans(): Promise<{ data: TreatmentPlanDto[] }> {
    return medcardClient.get(`${RESOURCE}/`, { camelize: true })
  },
  getTreatmentPlanDetails(id: string): Promise<{ data: TreatmentPlanDetailsDto }> {
    return medcardClient.get(`${RESOURCE}/${id}/`, { camelize: true })
  },
  getUnconfirmedTreatmentPlans() {
    return infoClient.get<UnconfirmedTreatmentPlanDto[]>(
      `${RESOURCE}/unconfirmed_dental/`,
      { camelize: true },
    )
  },
}
