import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  setup: function setup(props) {
    var colors = computed(function () {
      if (props.dark) {
        return {
          background: 'ui-kit-text',
          title: 'ui-kit-bg-gray-0--text',
          subtitle: 'ui-kit-text-secondary--text'
        };
      }
      return {
        background: 'ui-kit-bg-gray-0',
        title: 'ui-kit-text--text',
        subtitle: 'ui-kit-text-secondary--text'
      };
    });
    return {
      colors: colors
    };
  }
});