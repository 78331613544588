import type { Plugin } from '@nuxt/types'

// https://promo.mail.ru/atc-button/
export default <Plugin> function({ $sentry }) {
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('src', 'https://calendarx.imgsmail.ru/atc-button/latest/atc-button.js')
  document.head.appendChild(script)

  script.addEventListener('error', () => {
    $sentry.captureMessage('Mail Calendar failed to load')
  })
}
