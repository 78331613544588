import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["click"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { isNumber } from 'lodash-es';
import isNumeric from 'validator/lib/isNumeric';
import { NO_AVATAR } from '~/core/constants';

/**
 * Компонент использующий нативный HTML <img>.
 * Нужен только для того чтобы скрывать содержимое в ЯндексМетрике.
 * */
var __default__ = defineComponent({
  name: 'BaseImg',
  inheritAttrs: false,
  props: {
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    src: {
      type: String,
      required: true
    },
    ymHide: {
      type: Boolean,
      default: true
    },
    contain: {
      type: Boolean,
      default: false
    },
    // Устанавливает свойство object-fit
    cover: {
      type: Boolean,
      default: false
    },
    // Устанавливает свойство object-fit
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 'auto'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto'
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto'
    },
    minWidth: {
      type: [Number, String],
      default: 'auto'
    },
    minHeight: {
      type: [Number, String],
      default: 'auto'
    },
    aspectRatio: {
      type: [Number, String],
      default: 'auto'
    }
  },
  emits: ['click'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      listeners = _ref.listeners;
    var restListeners = computed(function () {
      var _ = listeners.click,
        rest = _objectWithoutProperties(listeners, _excluded);
      return rest;
    });
    var formattedSizeStyles = computed(function () {
      var formatSize = function formatSize(val) {
        return isNumber(val) || isNumeric(val) ? "".concat(val, "px") : val;
      };
      var fields = ['width', 'height', 'maxWidth', 'maxHeight', 'minWidth', 'minHeight'];
      return fields.reduce(function (acc, item) {
        return Object.assign(acc, _defineProperty({}, item, formatSize(props[item])));
      }, {});
    });
    var imgStyles = computed(function () {
      var objectFit = 'fill';
      if (props.contain) {
        objectFit = 'contain';
      } else if (props.cover) {
        objectFit = 'cover';
      }
      return _objectSpread(_objectSpread({}, formattedSizeStyles.value), {}, {
        objectFit: objectFit
      });
    });
    var imgClasses = computed(function () {
      var res = [{
        'ym-hide-content': props.ymHide
      }];
      if (Array.isArray(props.classes)) {
        return [].concat(res, _toConsumableArray(props.classes));
      }
      return [].concat(res, [props.classes]);
    });
    function emitClick() {
      emit('click');
    }
    return {
      NO_AVATAR: NO_AVATAR,
      restListeners: restListeners,
      formattedSizeStyles: formattedSizeStyles,
      imgStyles: imgStyles,
      imgClasses: imgClasses,
      emitClick: emitClick
    };
  }
});
import { useCssVars as _useCssVars } from 'vue';
var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_vm, _setup) {
    return {
      "f6cece64": _vm.aspectRatio
    };
  });
};
var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;