export default defineComponent({
  name: 'BaseSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    textRight: {
      type: Boolean,
      default: false
    },
    switchClass: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var lazyValue = ref(props.value);
    watch(function () {
      return props.value;
    }, function (val) {
      lazyValue.value = val;
    }, {
      immediate: true
    });
    watch(lazyValue, function (val) {
      if (val !== props.value) {
        emit('input', val);
      }
    });
    return {
      lazyValue: lazyValue
    };
  }
});