import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.small.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import BaseImg from '~/core/components/Base/BaseImg.vue';
var MEDIA_PREVIEW_SIZES = {
  IMAGE: {
    SMALL: 40,
    DEFAULT: 80,
    LARGE: 112
  },
  DELETE_ICON: {
    SMALL: 16,
    DEFAULT: 24
  }
};
export default defineComponent({
  name: 'MediaPreview',
  components: {
    BaseImg: BaseImg
  },
  props: {
    ymHide: {
      type: Boolean,
      default: false
    },
    src: {
      type: [String, File],
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    hintColor: {
      type: String,
      default: 'ui-kit-text-info'
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'ui-kit-icon-secondary'
    },
    imgProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    fromMedcard: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click:delete', 'click:icon', 'click'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      slots = _ref.slots;
    var innerSrc = ref('');
    var isOverlayVisible = computed(function () {
      return props.loading || Boolean(props.icon) || Boolean(slots.icon);
    });
    var imageSize = computed(function () {
      var _MEDIA_PREVIEW_SIZES$ = MEDIA_PREVIEW_SIZES.IMAGE,
        DEFAULT = _MEDIA_PREVIEW_SIZES$.DEFAULT,
        SMALL = _MEDIA_PREVIEW_SIZES$.SMALL,
        LARGE = _MEDIA_PREVIEW_SIZES$.LARGE;
      if (props.fromMedcard) {
        return LARGE;
      }
      return props.small ? SMALL : DEFAULT;
    });
    var deleteSize = computed(function () {
      var _MEDIA_PREVIEW_SIZES$2 = MEDIA_PREVIEW_SIZES.DELETE_ICON,
        DEFAULT = _MEDIA_PREVIEW_SIZES$2.DEFAULT,
        SMALL = _MEDIA_PREVIEW_SIZES$2.SMALL;
      return props.small ? SMALL : DEFAULT;
    });
    watch(function () {
      return props.src;
    }, function (val) {
      innerSrc.value = val instanceof File ? URL.createObjectURL(val) : val;
    }, {
      immediate: true
    });
    function handleLoad() {
      if (props.src instanceof File) {
        URL.revokeObjectURL(innerSrc.value);
      }
    }
    function emitClickDelete() {
      emit('click:delete');
    }
    function emitClickIcon() {
      emit('click:icon');
    }
    function emitClick() {
      emit('click');
    }
    return {
      innerSrc: innerSrc,
      handleLoad: handleLoad,
      isOverlayVisible: isOverlayVisible,
      imageSize: imageSize,
      deleteSize: deleteSize,
      emitClickDelete: emitClickDelete,
      emitClickIcon: emitClickIcon,
      emitClick: emitClick
    };
  }
});