import "core-js/modules/es.regexp.exec.js";
import Cookies from 'js-cookie';
import { CookieKey } from '~/core/enums';
import { CORE_MUTATIONS } from '~/core/constants';
import { isAppSupported } from '~/core/functions';
export var useSmartAppBannerSetup = function useSmartAppBannerSetup() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store,
    $device = _useNuxtApp.$device,
    $sentry = _useNuxtApp.$sentry;
  onBeforeMount(function () {
    var isYaBrowser = /YaBrowser/i.test(navigator.userAgent);
    var isIOSSafari = $device.isIos && $device.isSafari && !isYaBrowser;
    var isCookieWasSet = Cookies.get(CookieKey.AppSmartBannerWasClosed);
    var isActive = !isCookieWasSet && !isIOSSafari && isAppSupported({
      $device: $device,
      $sentry: $sentry
    });
    $store.commit(CORE_MUTATIONS.SET_IS_SMART_APP_BANNER_ACTIVE, isActive);
  });
};