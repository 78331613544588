import "core-js/modules/es.number.constructor.js";
import { NO_AVATAR } from '~/core/constants';

/**
 * Компонент обёртка вокруг v-avatar с возможностью добавления border'а */
export default defineComponent({
  name: 'AvatarWrapper',
  props: {
    width: {
      type: [String, Number],
      default: 40
    },
    height: {
      type: [String, Number],
      default: 40
    },
    src: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: false
    },
    imgProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup() {
    return {
      NO_AVATAR: NO_AVATAR
    };
  }
});