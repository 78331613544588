export var useConfirmEmail = function useConfirmEmail() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var storeEmail = computed(function () {
    return $store.state.profile.email;
  });
  var masterMedcardToken = computed(function () {
    return $store.state.medcard.masterToken;
  });
  return {
    storeEmail: storeEmail,
    masterMedcardToken: masterMedcardToken
  };
};