import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { isString } from 'lodash-es';
import { MediaPreview } from '~/core/components/MediaPreview';
import { isHeic, isImage, isPDF } from '~/core/functions';
import { NO_AVATAR } from '~/core/constants';
export default defineComponent({
  name: 'MediaSources',
  components: {
    MediaPreview: MediaPreview
  },
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    defaultRetryMessage: {
      type: String,
      default: 'Не загружен'
    },
    fromMedcard: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click:icon', 'click:delete', 'click:item', 'click:edit', 'click:retry'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var innerItems = computed(function () {
      return props.items.map(function (item) {
        var _item$loading, _item$deletable;
        var isFile = item.src instanceof File;
        var isPDFFile = isFile ? isPDF(item.src) : false;
        var isHeicFile = isFile ? isHeic(item.src) : false;
        var isImageFile = isFile ? isImage(item.src) : false;
        return {
          key: item.id,
          value: {
            src: getItemSrc(item.src, isHeicFile, isPDFFile),
            hint: getItemHint(item),
            hintColor: getItemHintColor(item),
            icon: getItemIcon(item),
            iconColor: item.iconColor || 'ui-kit-icon-secondary',
            loading: (_item$loading = item.loading) !== null && _item$loading !== void 0 ? _item$loading : false,
            deletable: (_item$deletable = item.deletable) !== null && _item$deletable !== void 0 ? _item$deletable : false,
            bordered: isPDFFile || isHeicFile,
            imgProps: {
              maxWidth: isPDFFile || isHeicFile ? 24 : '100%',
              maxHeight: isPDFFile || isHeicFile ? 24 : '100%',
              contain: isPDFFile || isHeicFile,
              cover: !isFile || isImageFile && !isHeicFile,
              lazySrc: NO_AVATAR
            }
          }
        };
      });
    });
    function getItemIcon(item) {
      if (item.retry) {
        return 'ui-icon-reset';
      }
      if (item.editable) {
        return 'ui-icon-create';
      }
      return item.icon || '';
    }
    function getItemSrc(src, isHeic, isPDF) {
      if (isString(src)) {
        return src;
      }
      if (isHeic) {
        return '/graphics/icons/files/HEIC.svg';
      }
      if (isPDF) {
        return '/graphics/icons/files/PDF.svg';
      }
      return src;
    }
    function getItemHint(item) {
      if (item.retry) {
        return isString(item.retry) ? item.retry : props.defaultRetryMessage;
      }
      return item.errorMessage || item.successMessage || item.hint || '';
    }
    function getItemHintColor(item) {
      if (item.errorMessage || item.retry) {
        return 'secondary';
      }
      if (item.successMessage) {
        return 'success';
      }
      return item.hintColor || 'ui-kit-text-info';
    }
    function handleClickItem(index) {
      emit('click:item', {
        index: index,
        item: props.items[index]
      });
    }
    function handleClickIcon(index) {
      var payload = {
        index: index,
        item: props.items[index]
      };
      emit('click:icon', payload);
      if (payload.item.retry) {
        emit('click:retry', payload);
        return;
      }
      if (payload.item.editable) {
        emit('click:edit', payload);
      }
    }
    function emitClickDelete(index) {
      emit('click:delete', {
        index: index,
        item: props.items[index]
      });
    }
    return {
      innerItems: innerItems,
      handleClickItem: handleClickItem,
      handleClickIcon: handleClickIcon,
      emitClickDelete: emitClickDelete
    };
  }
});