import type { Route } from 'vue-router'
import type { Device } from '@nuxtjs/device'
import type { Context } from '@nuxt/types'
import { isAppSupported } from '~/core/functions/userAgent/isAppSupported'
import { ReservedQueryKey } from '~/core/enums/routing'

const pages = [
  'medcard',
  'achievements',
  'appointments',
  'unconfirmed-documents',
]

const checkPages = (route: Route) => {
  for (const page of pages) {
    if (route.name?.startsWith(page)) {
      return true
    }
  }

  return false
}

/**
 * Позволяет проверить является ли страница ведущей ТОЛЬКО в App.
 * */
export const isDeeplinkOnlyPage = (route: Route, device: Device, $sentry: Context['$sentry']) =>
  !device.isDesktop &&
  checkPages(route) &&
  route.query[ReservedQueryKey.DeeplinkOnly] &&
  isAppSupported({ $device: device, $sentry })
