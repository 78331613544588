import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.small.js";
import "core-js/modules/web.dom-collections.for-each.js";
var COLORS = ['primary', 'secondary', 'red', 'white', 'default'];
/**
 * Компонент-обертка над v-btn.
 * Меняет стиль текса под material 3.
 * Добавляет slot и prop prepend-icon.
 * */
export default defineComponent({
  name: 'BaseBtn',
  inheritAttrs: false,
  props: {
    small: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    /**
     * Не будет работать если color="secondary"
     * */
    text: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: ''
    },
    /**
     * primary, secondary, red, default - специальные цвета для кнопок из ui-kit (Не путать с основными цветами ui-kit).
     * Помимо этих цветом можно также использовать основные цвета ui-kit (Отличные от указанных)
     * */
    color: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, ctx) {
    var classes = computed(function () {
      return ['base-button', _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, "base-button_".concat(props.color), COLORS.includes(props.color)), 'base-button_outlined', props.outlined), 'base-button_text', props.text), 'base-button_small', props.small)];
    });
    var binds = computed(function () {
      return _objectSpread(_objectSpread({
        depressed: true
      }, ctx.attrs), {}, {
        small: props.small,
        outlined: props.outlined,
        text: props.text,
        color: COLORS.includes(props.color) ? '' : props.color
      });
    });
    return {
      binds: binds,
      classes: classes
    };
  }
});