import type { AxiosError } from 'axios'
import type { CaptureContext, Contexts, Primitive } from '@sentry/types'

type Payload = {
  error: unknown,
  tags: Record<string, Primitive>
}

const isAxiosError = (error: unknown): error is Required<AxiosError<Record<string, unknown>>> => {
  if (typeof error !== 'object' || !error) {
    return false
  }

  return (
    'isAxiosError' in error &&
    (error as AxiosError).isAxiosError &&
    Boolean((error as AxiosError).response)
  )
}

export const composeSentryContext = ({ error, tags: customTags }: Payload): CaptureContext => {
  let tags: Partial<Record<string, Primitive>> = customTags
  let contexts: Contexts = { }

  if (isAxiosError(error)) {
    tags = {
      ...tags,
      request_error: true,
      request_status: error.response.status,
    }

    contexts = {
      response_data: error.response.data,
    }
  }

  return {
    tags,
    contexts,
  }
}
