import type { CanvasData, CroppedCanvasOptions, VueCropperMethods } from 'vue-cropperjs'
import type { Ref } from 'vue'
import { FileType } from '~/core/enums'
import { DEFAULT_MIN_IMAGE_HEIGHT, DEFAULT_MIN_IMAGE_WIDTH } from '~/core/constants'

type UseCropperMethodsReturn = {
  reset: () => void
  zoom: (ratio: number) => void
  rotateWithReset: (degree: -90 | -180 | 90 | 180) => void
  replace: (src: string) => void
  getCroppedFile: (options?: CroppedCanvasOptions & { fileName?: string }) => Promise<File>
}

type UseCropperMethods = (cropper: Ref<VueCropperMethods | null>) => UseCropperMethodsReturn

export const useCropperMethods: UseCropperMethods = (cropper) => {
  function reset() {
    cropper.value?.reset()
  }

  function zoom(ratio = 0.1) {
    cropper.value?.relativeZoom(ratio)
  }

  /**
   * Поворачивает картинку, восстанавливая её исходное состояние.
   */
  function rotateWithReset(degree: -90 | -180 | 90 | 180 = -90) {
    if (!cropper.value) {
      return
    }

    const containerData = cropper.value.getContainerData()
    cropper.value.clear()
    cropper.value.rotate(degree)
    let canvasData = cropper.value.getCanvasData()

    const resetCanvasData = () => {
      canvasData = cropper.value!.getCanvasData()
    }

    const moveCanvasToCenterBy = (direction: 'vertical' | 'horizontal') => {
      const positionProp = direction === 'vertical' ? 'top' : 'left'
      const sizeProp = direction === 'vertical' ? 'height' : 'width'

      resetCanvasData()

      cropper.value!.setCanvasData({
        [positionProp]: (containerData[sizeProp] - canvasData[sizeProp]) / 2,
      } as unknown as CanvasData)
    }

    // При сохранении пропорций изображения после каждого вызова setCanvasData для изменения высоты/ширины
    // меняется противоположная величина (ширина/высота)
    if (canvasData.width > canvasData.height) {
      cropper.value.setCanvasData({
        width: containerData.width,
        left: 0,
      } as CanvasData)

      resetCanvasData()

      if (canvasData.height > containerData.height) {
        cropper.value.setCanvasData({
          top: 0,
          height: containerData.height,
        } as CanvasData)

        moveCanvasToCenterBy('horizontal')
      } else {
        moveCanvasToCenterBy('vertical')
      }
    } else {
      cropper.value.setCanvasData({
        height: containerData.height,
        top: 0,
      } as CanvasData)

      resetCanvasData()

      if (canvasData.width > containerData.width) {
        cropper.value.setCanvasData({
          left: 0,
          width: containerData.width,
        } as CanvasData)

        moveCanvasToCenterBy('vertical')
      } else {
        moveCanvasToCenterBy('horizontal')
      }
    }

    resetCanvasData()
    cropper.value.initCrop()
    cropper.value.setCropBoxData({
      top: canvasData.top,
      left: canvasData.left,
      width: canvasData.width,
      height: canvasData.height,
    })
  }

  function replace(src: string) {
    cropper.value?.replace(src)
  }

  function getCroppedFile(options?: CroppedCanvasOptions & { fileName?: string }): Promise<File> {
    const defaultOptions: CroppedCanvasOptions = {
      fillColor: '#ffffff',
      minHeight: DEFAULT_MIN_IMAGE_HEIGHT,
      minWidth: DEFAULT_MIN_IMAGE_WIDTH,
    }

    const canvas = cropper.value?.getCroppedCanvas({
      ...defaultOptions,
      ...options,
    })

    return new Promise((resolve) => {
      canvas?.toBlob((blob) => {
        resolve(new File([blob!], options?.fileName || 'Изображение', { type: FileType.Jpeg }))
        // Подобрано. При значениях больше 0.7 размер картинки увеличивается, если не было изменений.
      }, FileType.Jpeg, 0.7)
    })
  }

  return {
    reset,
    zoom,
    rotateWithReset,
    replace,
    getCroppedFile,
  }
}
