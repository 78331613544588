import { setImage } from '~/features/Rates/functions';
import { RateType } from '~/features/Rates/enums';
export default defineComponent({
  name: 'RateAbout',
  props: {
    about: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showFavourites: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    },
    pdUrl: {
      type: String,
      default: ''
    }
  },
  emits: ['click:favourite'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var hasFavouriteBtn = computed(function () {
      return props.about.inUserFavourites !== null && props.showFavourites;
    });
    function handleSetImage() {
      return setImage(props.about.avatar, props.about.rateType);
    }
    function handleClickFavourite(ev) {
      if (props.isLoading) {
        return;
      }
      emitClickFavourite();
      if (ev.currentTarget instanceof HTMLElement && ev.currentTarget.tagName === 'BUTTON') {
        ev.currentTarget.blur();
      }
    }
    function handleClickDoctorInfo(ev) {
      if (props.pdUrl) {
        window.open(props.pdUrl);
      }
      if (ev.currentTarget instanceof HTMLElement && ev.currentTarget.classList.contains('v-card')) {
        ev.currentTarget.blur();
      }
    }
    function emitClickFavourite() {
      emit('click:favourite');
    }
    return {
      RateType: RateType,
      hasFavouriteBtn: hasFavouriteBtn,
      handleSetImage: handleSetImage,
      handleClickFavourite: handleClickFavourite,
      handleClickDoctorInfo: handleClickDoctorInfo
    };
  }
});