import "core-js/modules/web.timers.js";
import { TIMER_TIMEOUT } from '~/core/constants';
import { formatSecondsToValidTime } from '~/features/Profile/functions/format-seconds-to-valid-time';
export var useTimer = function useTimer() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ref({
    words: false
  });
  var codeTimer = ref(null);
  var intervalId = ref(0);
  var formattedTimer = computed(function () {
    if (!codeTimer.value) {
      return;
    }
    return formatSecondsToValidTime(codeTimer.value, options.value);
  });
  watch(codeTimer, function (val) {
    if (val && val <= 0) {
      clearTimer();
    }
  });
  function startCodeTimer(time) {
    clearTimer();
    codeTimer.value = time || TIMER_TIMEOUT;
    intervalId.value = window.setInterval(function () {
      codeTimer.value--;
    }, 1000);
  }
  function clearTimer() {
    codeTimer.value = null;
    window.clearInterval(intervalId.value);
  }
  return {
    codeTimer: codeTimer,
    formattedTimer: formattedTimer,
    startCodeTimer: startCodeTimer,
    clearTimer: clearTimer
  };
};